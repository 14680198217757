/*eslint no-case-declarations: "off"*/

import {
  Maybe,
  Feedback,
  FeedbackSheetAuthorTypes,
  FeedbackSheet,
  FeedbackSheetItem,
  FeedbackSchedule,
  FeedbackSheetFormStatuses,
  FeedbackSheetForm,
  User,
  FeedbackLayerTypes,
  FeedbackSheetLayer,
  FeedbackMiddleLayer,
  FeedbackSenderPublicationRanges,
  FeedbackLayer,
  FeedbackSheetItemTypes,
  FeedbackSheetItemElementTypes,
  FeedbackScheduleStatuses,
  Objective,
  ObjectiveCategory,
  FeedbackSheetObjectiveItem,
  FeedbackSheetObjectiveItemTemplate,
  FeedbackSheetTargetObjectiveTypes,
  FeedbackMiddleLayerTypes,
  FeedbackSheetLayerMember,
  FeedbackSheetLayerMemberStatuses,
  ReferenceLinkInput,
  FeedbackSheetItemTemplate,
  Okr,
  OkrTypes,
  FeedbackSheetObjectiveItemElement,
  KeyResult,
  ReferenceLink,
  FeedbackSheetListItem,
  FeedbackSheetListItemTemplate,
  FeedbackSheetListItemElement,
  FeedbackSheetItemTemplateSaveInput,
  FeedbackSheetNumberItem,
  FeedbackSheetGradeItem,
  FeedbackSheetCommentItem,
  FeedbackSheetSingleLineItem,
  FeedbackSheetItemSaveInput,
  OkrStatuses,
  ObjectiveWeight,
  FeedbackSheetPropertyItem,
  ObjectiveSheetSchedule,
  SystemFeedbackSheetTemplate,
  CompanyFeedbackSheetTemplate,
  FeedbackSheetTotalScoreSourceTypes,
  FeedbackSheetItemAuthorTypes,
  FeedbackPublicationTypes,
  FeedbackSheetLayerRequestedData,
  FeedbackSheetCalculateItem,
  FeedbackSheetGuidelineItem,
  FeedbackSheetSectionItem,
  FeedbackSheetTemplate,
  FeedbackSheetGradeItemTemplate,
  FeedbackSheetNumberItemTemplate,
  FeedbackRequestPreValue,
} from '../generated/graphql';
import isSameDay from 'date-fns/isSameDay';
import isAfter from 'date-fns/isAfter';
import endOfDay from 'date-fns/endOfDay';
import {
  sortWithoutBreaking,
  hasElement,
  takeAwayEmptyElement,
  takeAwayMaybeElement,
  getArrayIds,
} from './array';
import {
  comparatorIdDesc,
  comparatorFeedbackSheetMember,
  comparatorObjectiveOrder,
} from './comparators';
import { comparatorOrder } from './comparators';
import { changeOrderToCircledNumber, isUndefined } from './string';
import i18next from 'i18next';
import { isAfterDate, isBeforeDate, isSameOrAfter } from './date-fns';
import { UseDictionnaryReturn } from '../lib/use-me';
import { omit, isNumber } from '../utils/lodash';
import {
  FeedbackDataSheetLayer,
  FeedbackDataValues,
} from '../components/organisms/FeedbackSettingsStepProcess/types';
import { FeedbackDataSheet } from '../components/organisms/_forms/FeedbackSettingsForms/FeedbackSettingsLayers/types';
import { isSameOkr, getActiveOkrs } from './objective';
import { changeNullPropsToEmptyString } from './object';
import { FeedbackLayerData } from '../components/pages/PlanStandard/Hr/PeopleManagement/Feedbacks/Feedbacks/Detail/Forms/presenter';
import i18n from '../lib/i18n';
import { convertValue } from '../components/organisms/_forms/TextEditor/utils';

export type FeedbackSheetTypeWithPreValue = FeedbackSheetGradeItem;
export type FeedbackSheetAllTypeItem =
  | FeedbackSheetItem
  | FeedbackSheetCalculateItem
  | FeedbackSheetCommentItem
  | FeedbackSheetNumberItem
  | FeedbackSheetGradeItem
  | FeedbackSheetGuidelineItem
  | FeedbackSheetListItem
  | FeedbackSheetObjectiveItem
  | FeedbackSheetPropertyItem
  | FeedbackSheetSectionItem;

export const isMiddleParallelLayerType = (feedback: Maybe<Feedback> | undefined) =>
  feedback?.middleLayerType === FeedbackMiddleLayerTypes.Parallel;

export const getMyDueDate = (schedule?: FeedbackSchedule | null) => {
  if (!schedule || !hasElement(schedule?.layers)) return 0;
  const mySetting = schedule?.layers?.find(
    s => s && s.authorType === FeedbackSheetAuthorTypes.Self,
  );
  return (mySetting || {}).dueDate;
};

export const getReceiverId = (sheet: FeedbackSheet) => sheet?.receiver?.id!;

export const isReceiver = (sheet: FeedbackSheet, myUserId: string) =>
  getReceiverId(sheet) === myUserId;

export const isSender = (sheet: FeedbackSheet, myUserId: string) =>
  (sheet?.layers || []).some(
    layer =>
      (layer?.type === FeedbackLayerTypes.Middle || layer?.type === FeedbackLayerTypes.Final) &&
      pickFeedbackSheetLayerAssignees(layer)?.find(assignee => assignee?.id === myUserId),
  );
export const isFinalSender = (sheet: FeedbackSheet, myUserId: string) =>
  (sheet?.layers || []).some(
    layer =>
      layer?.type === FeedbackLayerTypes.Final &&
      pickFeedbackSheetLayerAssignees(layer)?.find(assignee => assignee?.id === myUserId),
  );
export const isReader = (sheet: FeedbackSheet, myUserId: string) =>
  (sheet?.layers || []).some(
    layer =>
      layer?.type === FeedbackLayerTypes.Read &&
      pickFeedbackSheetLayerAssignees(layer)?.find(assignee => assignee?.id === myUserId),
  );

export const isSupervisor = (sheet: FeedbackSheet, myUserId: string) =>
  (sheet?.layers || []).some(
    layer =>
      layer?.type === FeedbackLayerTypes.Supervise &&
      pickFeedbackSheetLayerAssignees(layer)?.find(assignee => assignee?.id === myUserId),
  );

export const isAlertFeedbackSheetForm = (f: FeedbackSheetForm | undefined, myUserId: string) => {
  if (!f) return false;
  if (f.from?.every(from => from && +from.id !== +myUserId)) return false;
  if (f.status !== FeedbackSheetFormStatuses.Draft) return false;
  if (f.isArchived) return false;

  return !!f?.layer?.dueDate && isAfter(new Date(), endOfDay(f.layer?.dueDate));
};

export const getAlertedForm = (sheet: FeedbackSheet, myUserId: string) =>
  ((sheet || {}).forms || []).filter(f => isAlertFeedbackSheetForm(f, myUserId));

export const hasAlertedForm = (sheet: FeedbackSheet, myUserId: string) => {
  return getAlertedForm(sheet, myUserId).length > 0;
};

export const extractAlertedSheets = (
  sheets: FeedbackSheet[],
  schedules: FeedbackSchedule[],
  myUserId: string,
) => {
  return (sheets || [])
    .filter((sheet: FeedbackSheet) => isReceiver(sheet, myUserId))
    .filter((sheet: FeedbackSheet) => hasAlertedForm(sheet, myUserId))
    .map((sheet: FeedbackSheet) => ({
      ...sheet,
      name: schedules?.find(s => s && sheet.schedule && +s.id === +sheet.schedule?.id)?.name,
      user: sheet.receiver,
    }))
    .sort(comparatorIdDesc);
};

export const mapSchedulesToItems = (schedules: FeedbackSchedule[]): SelectOption[] => {
  return schedules?.map(schedule => ({
    label: isFeedbackScheduleArchived(schedule)
      ? `${schedule?.name!} (${i18next.t('アーカイブ済')})`
      : schedule?.name!,
    value: schedule?.id!,
  }));
};

export const canReadFeedbackSheet = (sheet: FeedbackSheet, userId: string) => {
  if (!sheet || !sheet.id || !userId) return false;

  return (
    sheet?.receiver?.id === userId ||
    (sheet?.layers || []).some(l =>
      pickFeedbackSheetLayerAssignees(l)?.some(assignee => assignee?.id === userId),
    )
  );
};

export interface GetMyFeedbackSheetFormParams {
  formId?: string;
}

export const getMyFeedbackSheetForms = (
  sheet: FeedbackSheet | undefined,
  userId: string,
  params: GetMyFeedbackSheetFormParams = {},
): FeedbackSheetForm[] => {
  if (!sheet?.id || !userId || !hasElement(sheet.forms)) return [];
  return takeAwayMaybeElement<FeedbackSheetForm[]>(sheet?.forms).filter(
    f => f && (f?.from || []).some(fr => fr?.id === userId),
  );
};

export const getMyFeedbackSheetForm = (
  sheet: FeedbackSheet | undefined,
  userId: string,
  params: GetMyFeedbackSheetFormParams = {},
) => {
  const forms = getMyFeedbackSheetForms(sheet, userId);
  if (forms.length > 1) {
    if (params.formId) {
      return forms.find(f => f.id === params.formId);
    }
  }
  return forms[0] || null;
};

export const getMyFeedbackSheetFormItems = (
  sheet: FeedbackSheet,
  userId: string,
  params: GetMyFeedbackSheetFormParams = {},
): FeedbackSheetAllTypeItem[] => {
  const myForm = getMyFeedbackSheetForm(sheet, userId, params);
  if (!myForm) return [];

  const isSelfFeedback = isReceiver(sheet, userId);
  const myFormItems = takeAwayMaybeElement<(FeedbackSheetItem & FeedbackSheetObjectiveItem)[]>(
    myForm?.items,
  );
  return myFormItems.filter(item => {
    if (isSelfFeedback) {
      return item.itemTemplate?.authorTypes?.includes(FeedbackSheetItemAuthorTypes.Self);
    }
    return item.itemTemplate?.authorTypes?.includes(FeedbackSheetItemAuthorTypes.Sender);
  });
};

export const isNowAfterStartDateWithFormAndSchedule = (
  form: FeedbackSheetForm,
  schedule: FeedbackSchedule,
  myUserId: string,
  now = new Date(),
) => {
  const formLayerType = form?.layer?.type;
  if (!formLayerType) return false;

  const layer = takeAwayMaybeElement<FeedbackLayer[]>(schedule?.layers).find(
    layer => layer.type === formLayerType,
  );
  if (!layer) return false;

  return isSameDay(now, new Date(layer.startDate)) || isAfter(now, new Date(layer.startDate));
};

export const isNowAfterStartDate = (sheet: FeedbackSheet, myUserId: string, now = new Date()) => {
  const myForm = getMyFeedbackSheetForm(sheet, myUserId);
  if (!myForm) return false;

  const schedule = sheet?.schedule;
  if (!schedule) return false;

  return isNowAfterStartDateWithFormAndSchedule(myForm, schedule, myUserId, now);
};

export const isPublished = (status: FeedbackSheetFormStatuses) =>
  status === FeedbackSheetFormStatuses.Published;

export const isSubmitted = (status: FeedbackSheetFormStatuses) =>
  status === FeedbackSheetFormStatuses.Submitted;

export const isDraft = (status: FeedbackSheetFormStatuses) =>
  status === FeedbackSheetFormStatuses.Draft;

export const isSubmittedOrPublished = (status: FeedbackSheetFormStatuses) =>
  isPublished(status) || isSubmitted(status);

export const isFormDraft = (form: Maybe<FeedbackSheetForm>) =>
  isDraft(form?.status!) && (form?.version || 0) > 0;

export const isFormSubmittedOrPublished = (form: Maybe<FeedbackSheetForm>) =>
  isSubmittedOrPublished(form?.status!);

export const isFormSubmitted = (form: Maybe<FeedbackSheetForm>) => isSubmitted(form?.status!);

export const getMyForm = (sheet: FeedbackSheet, myUserId: string): FeedbackSheetForm | null => {
  return sheet?.forms?.find(form => form?.from?.some(f => f?.id === myUserId)) ?? null;
};

export const getVisibleForms = (sheet: FeedbackSheet, myUserId: string): FeedbackSheetForm[] => {
  const myForm = getMyForm(sheet, myUserId);

  if ((sheet as any)?.feedback) {
    console.warn('getVisibleForms / feedback is not supposed to be inside sheet');
  }

  const feedback = sheet?.schedule?.feedback;

  const forms: FeedbackSheetForm[] = takeAwayMaybeElement<FeedbackSheetForm[]>(sheet?.forms).filter(
    form => {
      if (!form) {
        return false;
      }

      //自分のフォームの場合に、ドラフトでも表示する
      if (myForm?.id === form?.id) {
        return isFormDraft(form) || isFormSubmittedOrPublished(form);
      }
      return isFormSubmittedOrPublished(form);
    },
  );

  /** 閲覧者・管理者の場合 */
  if (isReader(sheet, myUserId) || isSupervisor(sheet, myUserId)) {
    /** 閲覧者・管理者はすべてのフォームが見れる */
    return forms;
  }

  /** 評価者の場合 */
  if (isSender(sheet, myUserId)) {
    /** 最終評価者はすべてのフォームが見れる */
    if (myForm?.layer?.type === FeedbackLayerTypes.Final) {
      if (feedback?.enableMiddleFeedback) {
        return forms;
      } else {
        return forms.filter(
          form =>
            form?.layer?.type === FeedbackLayerTypes.Self ||
            (form?.layer?.type === FeedbackLayerTypes.Final &&
              form?.clusterId === myForm?.clusterId),
        );
      }
    }

    if (myForm?.layer?.type === FeedbackLayerTypes.Middle) {
      /** 公開範囲が「共有しない」場合は自分のFBとセルフFBのみ見れる */
      if (feedback?.senderPublicationRange === FeedbackSenderPublicationRanges.None) {
        return forms.filter(
          form =>
            form.layer?.type === FeedbackLayerTypes.Self ||
            (form.layer?.type === FeedbackLayerTypes.Middle &&
              (form.layer as FeedbackMiddleLayer)?.order! ===
                (myForm?.layer as FeedbackMiddleLayer)?.order!),
        );
      }

      /** 中間評価者はセルフFB及び前の中間評価者のフォームが見れる */
      if (feedback?.senderPublicationRange === FeedbackSenderPublicationRanges.Hierarchy) {
        return forms.filter(
          form =>
            form.layer?.type === FeedbackLayerTypes.Self ||
            (form.layer?.type === FeedbackLayerTypes.Middle &&
              (form.layer as FeedbackMiddleLayer)?.order! <=
                (myForm?.layer as FeedbackMiddleLayer)?.order!),
        );
      }

      /** 公開範囲がAllの場合はすべてが見れる */
      if (feedback?.senderPublicationRange === FeedbackSenderPublicationRanges.All) {
        return forms;
      }
    }
  }

  /** 被評価者の場合 */
  if (isReceiver(sheet, myUserId)) {
    /** publishMiddleFeedbackがTrueならば、すべてのフォームが見れ、FalseならばセルフFBと最終評価のみ見れる */
    /** FIXME: publisheMiddleFeedbackだったらコメントのみ表示 */
    return feedback?.publishMiddleFeedback
      ? forms.filter(
          form =>
            form.layer?.type === FeedbackLayerTypes.Self ||
            (form.layer?.type === FeedbackLayerTypes.Middle &&
              form.status === FeedbackSheetFormStatuses.Published) ||
            (form.layer?.type === FeedbackLayerTypes.Final &&
              form.status === FeedbackSheetFormStatuses.Published),
        )
      : forms.filter(
          form =>
            form.layer?.type === FeedbackLayerTypes.Self ||
            (form.layer?.type === FeedbackLayerTypes.Final &&
              form.status === FeedbackSheetFormStatuses.Published),
        );
  }

  return [];
};

export interface FeedbackSheetLayerWithAccess extends FeedbackSheetLayer {
  access?: boolean | 'onlyComment' | 'onlyMine' | 'onlyRating' | 'onlyRatingAndComment';
  label: string;
}

export const getMyLayer = (sheet: FeedbackSheet, myUserId: string) => {
  const sheetLayers = takeAwayMaybeElement<FeedbackSheetLayer[]>(sheet?.layers);

  const myLayers = sheetLayers.filter(layer =>
    pickFeedbackSheetLayerAssignees(layer)?.some(assignee => assignee?.id === myUserId),
  );

  const myReadLayerIndex = myLayers.findIndex(layer => layer.type === FeedbackLayerTypes.Read);
  return myReadLayerIndex > -1 ? myLayers[myReadLayerIndex] : myLayers[0];
};

/** あなたが閲覧できる内容 */
export const getVisibleLayers = (
  sheet: FeedbackSheet,
  myUserId: string,
  fbDict?: UseDictionnaryReturn | undefined,
): FeedbackSheetLayerWithAccess[] => {
  const sheetLayers = takeAwayMaybeElement<FeedbackSheetLayer[]>(sheet?.layers);
  const myLayer = getMyLayer(sheet, myUserId);
  const feedback = sheet?.schedule?.feedback;

  if (!feedback || !myLayer) return [];

  return sheetLayers
    .map(layer => {
      const access = hasLayerAccessToLayer(
        feedback!,
        myLayer as FeedbackDataSheetLayer,
        layer as FeedbackDataSheetLayer,
      );

      const label = createLabelFromFeedbackSheetLayer(
        layer!,
        {
          isMiddleParallelLayer: isMiddleParallelLayerType(feedback),
        },
        fbDict,
      );

      return {
        ...layer,
        label,
        access,
      };
    })
    .filter(layer => !!layer.access);
};

/** FB回答時に「誰に見えるか」を表示 */
export const getLayersCanViewMyLayer = (
  sheet: FeedbackSheet,
  myUserId: string,
  params?: {
    fbDict: UseDictionnaryReturn;
    only?: Record<string, boolean> | null | undefined;
  },
): FeedbackSheetLayerWithAccess[] => {
  const only = params?.only;
  const fbDict = params?.fbDict;

  const sheetLayers = takeAwayMaybeElement<FeedbackSheetLayer[]>(sheet?.layers);
  const myLayer = sheetLayers.find(
    layer =>
      pickFeedbackSheetLayerAssignees(layer)?.some(assignee => assignee?.id === myUserId) &&
      layer.type !== FeedbackLayerTypes.Read,
  );

  const feedback = sheet?.schedule?.feedback || (sheet as any)?.feedback!;
  if ((sheet as any)?.feedback) {
    console.warn('getLayersCanViewMyLayer / feedback is not supposed to be inside sheet');
  }

  if (!feedback || !myLayer) return [];

  const layers = takeAwayEmptyElement<FeedbackSheetLayerWithAccess[]>(
    sheetLayers
      .map(layer => {
        const access = hasLayerAccessToLayer(
          feedback!,
          layer as FeedbackDataSheetLayer,
          myLayer as FeedbackDataSheetLayer,
        );

        const label = createLabelFromFeedbackSheetLayer(
          layer!,
          {
            onlyComment: access === 'onlyComment',
            isMiddleParallelLayer: isMiddleParallelLayerType(feedback),
          },
          fbDict,
        );

        return {
          ...layer,
          access,
          label,
        };
      })
      .filter(layer => {
        if (only) {
          if (only?.onlySender) {
            if (layer.authorType === FeedbackSheetAuthorTypes.Sender && layer.access) {
              return true;
            }
          }
          if (only?.onlyReader) {
            if (layer.authorType === FeedbackSheetAuthorTypes.Reader && layer.access) {
              return true;
            }
          }
          return false;
        }

        return layer.access;
      }),
  );

  return layers;
};

/** FBシートの「回答者」欄の表示 */
export const getLabelsCanWriteTheItem = (
  sheet: FeedbackSheet,
  itemTemplate: any,
  isMeReceiver: boolean,
  fbDict?: UseDictionnaryReturn,
) => {
  // itemTemplate.authorTypes に form の authorType が含まれるか && form の該当の item が itemEnabled になっているか
  const canWrite = (form: FeedbackSheetForm) =>
    itemTemplate?.authorTypes?.some((authorType: string) => authorType === form?.authorType) &&
    !!form?.wrapper?.items?.find(
      (itemWrapper: any) =>
        itemTemplate.id === itemWrapper?.itemTemplateId && itemWrapper?.itemEnabled,
    );
  // form の該当の item が itemEnabled かつ commentEnabled になっているか
  const canWriteComment = (form: FeedbackSheetForm) =>
    !!form?.wrapper?.items?.find(
      (itemWrapper: any) =>
        itemTemplate.id === itemWrapper?.itemTemplateId &&
        itemWrapper?.itemEnabled &&
        itemWrapper?.commentEnabled,
    );
  // layer の type からラベルを確定
  const toWriterLayerLabel = (form: FeedbackSheetForm) => {
    const layer: any = form.layer;
    switch (layer?.type) {
      case FeedbackLayerTypes.Self:
        return i18next.t('{{wSelfLayerAssignee}}', fbDict);
      case FeedbackLayerTypes.Middle:
        // 被評価者： 中間評価者のコメントしか閲覧できないので、commentEnabled でなければ回答者扱いしない
        if (isMeReceiver && !canWriteComment(form)) return null;
        return i18next.t('{{wMiddleLayerAssignee}}{{no}}', {
          ...fbDict,
          no: layer?.order ? changeOrderToCircledNumber(layer.order!) : '',
        });
      case FeedbackLayerTypes.Final:
        return sheet?.schedule?.feedback?.enableMiddleFeedback
          ? i18next.t('{{wFinalLayerAssignee}}', fbDict)
          : i18next.t('評価者');
      case FeedbackLayerTypes.Read:
        return i18next.t('{{wReadLayerAssignee}}', fbDict);
      case FeedbackLayerTypes.Supervise:
        return i18next.t('{{wSuperviseLayerAssignee}}', fbDict);
      default:
        return null;
    }
  };
  // form の layer と from (sender) からラベルを確定
  const toWriterLabels = (form: FeedbackSheetForm) => {
    const layerName = toWriterLayerLabel(form!);
    return layerName ? form.from?.map(sender => `${layerName}（${sender?.displayName}）`) : [];
  };

  const forms = sheet?.forms?.filter(form => canWrite(form!)) || [];
  const senders = forms?.map(form => form?.from).flat() || [];
  const labels = forms?.map(form => toWriterLabels(form!))?.flat() || [];
  return { senders, labels };
};

export const createLabelFromFeedbackSheetLayer = (
  layer: FeedbackSheetLayer,
  {
    onlyComment,
    isMiddleParallelLayer,
    isLayerLabel = false,
  }: {
    onlyComment?: boolean;
    isMiddleParallelLayer?: boolean;
    isLayerLabel?: boolean;
  },
  fbDict?: UseDictionnaryReturn | undefined,
) => {
  const commentOnlyLabel = onlyComment ? i18next.t('（コメントのみ）') : '';

  switch (layer?.type) {
    case FeedbackLayerTypes.Self:
      return `${
        isLayerLabel
          ? i18next.t('{{wSelfLayer}}', fbDict)
          : i18next.t('{{wSelfLayerAssignee}}', fbDict)
      }${commentOnlyLabel}`;

    case FeedbackLayerTypes.Middle:
      if (isMiddleParallelLayer) {
        return `${
          isLayerLabel
            ? i18next.t('{{wMiddleLayer}}', fbDict)
            : i18next.t('{{wMiddleLayerAssignee}}', fbDict)
        }${commentOnlyLabel}`;
      }

      return `${
        isLayerLabel
          ? i18next.t('{{wMiddleLayer}}{{no}}', {
              ...fbDict,
              no: layer.order ? changeOrderToCircledNumber(layer.order!) : '',
            })
          : i18next.t('{{wMiddleLayerAssignee}}{{no}}', {
              ...fbDict,
              no: layer.order ? changeOrderToCircledNumber(layer.order!) : '',
            })
      }${commentOnlyLabel}`;

    case FeedbackLayerTypes.Final:
      return isLayerLabel
        ? i18next.t('{{wFinalLayer}}', fbDict)
        : i18next.t('{{wFinalLayerAssignee}}', fbDict);
    case FeedbackLayerTypes.Read:
      return isLayerLabel
        ? i18next.t('{{wReadLayer}}', fbDict)
        : i18next.t('{{wReadLayerAssignee}}', fbDict);
    case FeedbackLayerTypes.Supervise:
      return isLayerLabel
        ? i18next.t('{{wSuperviseLayer}}', fbDict)
        : i18next.t('{{wSuperviseLayerAssignee}}', fbDict);
    default:
      return '';
  }
};

export const createLabelFromVisibleFeedbackSheetLayer = (
  layer: FeedbackSheetLayer,
  {
    isSelf,
    feedback,
  }: {
    isSelf: boolean;
    feedback?: Maybe<Feedback> | undefined | null;
  },
  fbDict?: UseDictionnaryReturn | undefined,
) => {
  const { middleLayerType, publishMiddleFeedbackRating, publishMiddleFeedback } = feedback || {};

  switch (layer?.type) {
    case FeedbackLayerTypes.Self:
      return layer?.hasForm ? i18next.t('{{wSelfLayer}}', fbDict) : null;
    case FeedbackLayerTypes.Middle:
      if (isSelf) {
        if (middleLayerType === FeedbackMiddleLayerTypes.Parallel) {
          if (publishMiddleFeedback && publishMiddleFeedbackRating) {
            return i18next.t('{{wMiddleLayer}}', fbDict);
          } else if (publishMiddleFeedbackRating) {
            return i18next.t('{{wMiddleLayer}}（レーティング、数値評価のみ）', fbDict);
          } else if (publishMiddleFeedback) {
            return i18next.t('{{wMiddleLayer}}（コメントのみ）', fbDict);
          }
        } else {
          //default Serial
          if (publishMiddleFeedback) {
            return i18next.t('{{wMiddleLayer}}{{no}}（コメントのみ）', {
              ...fbDict,
              no: layer.order ? changeOrderToCircledNumber(layer.order!) : '',
            });
          }
        }
      } else {
        if (middleLayerType === FeedbackMiddleLayerTypes.Parallel) {
          return i18next.t('{{wMiddleLayer}}', fbDict);
        } else {
          //default Serial
          return i18next.t('{{wMiddleLayer}}{{no}}', {
            ...fbDict,
            no: layer.order ? changeOrderToCircledNumber(layer.order!) : '',
          });
        }
      }
      break;

    case FeedbackLayerTypes.Final:
      return i18next.t('{{wFinalLayer}}', fbDict);
    case FeedbackLayerTypes.Read:
      return i18next.t('{{wReadLayer}}', fbDict);
    default:
      return '';
  }
};

export const errorTypes: any = (dict: UseDictionnaryReturn) => ({
  'feedback-assignee-final-email-enable-middle': i18next.t(
    '{{wFinalLayerAssignee}}が設定されていません',
    dict,
  ),
  'feedback-assignee-final-email-not-enable-middle': i18next.t('評価者が設定されていません'),
  'feedback-assignee-duplicate-email': i18next.t(
    '{{wMiddleLayerAssignee}}・{{wFinalLayerAssignee}}に同じユーザーを重複して設定することはできません',
    dict,
  ),
  'feedback-assignee-same-receiver-to-sender-reader': i18next.t(
    '評価者・{{wReadLayerAssignee}}に{{wSelfLayerAssignee}}と同じユーザーを設定することはできません',
    dict,
  ),
  'feedback-assignee-same-receiver-to-sender-supervisor': i18next.t(
    '評価者・{{wSuperviseLayerAssignee}}に{{wSelfLayerAssignee}}と同じユーザーを設定することはできません',
    dict,
  ),
  'feedback-assignee-same-receiver-to-sender': i18next.t(
    '評価者に{{wSelfLayerAssignee}}と同じユーザーを設定することはできません',
    dict,
  ),
  'feedback-assignee-same-receiver-to-reader': i18next.t(
    '{{wReadLayerAssignee}}に{{wSelfLayerAssignee}}と同じユーザーを設定することはできません',
    dict,
  ),
  'feedback-assignee-same-receiver-to-supervisor': i18next.t(
    '{{wSuperviseLayerAssignee}}に{{wSelfLayerAssignee}}と同じユーザーを設定することはできません',
    dict,
  ),
  'feedback-assignee-same-reader-to-supervisor': i18next.t(
    '{{wSuperviseLayerAssignee}}に閲覧者と同じユーザーを設定することはできません',
    dict,
  ),
  // 'feedback-assignee-final-email-only-one': i18next.t(
  //   '{{wFinalLayerAssignee}}を2名以上選択することはできません',
  //   dict,
  // ),
  'feedback-receiver-has-no-team': i18next.t(
    '{{wSelfLayerAssignee}}が{{wTeam}}に未所属です。{{wTeam}}を設定するか、前のページに戻り、「{{wTeam}}に未所属のメンバーも対象に含める」にチェックしてください',
    dict,
  ),
  'feedback-receiver-team-is-not-included-to-target': i18next.t(
    '{{wSelfLayerAssignee}}の所属{{wTeam}}が対象になってません。前のページに戻り、フィードバックの対象となる{{wTeam}}の設定をご確認ください',
    dict,
  ),
});

export const getEarliestStartDateAndLatestDueDateFromLayers = (
  layers: FeedbackLayer[],
): {
  startDate: Date | null;
  dueDate: Date | null;
} => {
  if (!layers || !layers.length) {
    return {
      startDate: null,
      dueDate: null,
    };
  }
  const startDates = layers
    ?.filter(layer => !!layer?.startDate)
    ?.map(layer => new Date(layer?.startDate));
  const dueDates = layers
    ?.filter(layer => !!layer?.dueDate)
    ?.map(layer => new Date(layer?.dueDate));

  const earliestStartDate = startDates.reduce((pre, current) => {
    return pre > current ? current : pre;
  }, startDates[0]);

  const latestDueDate = dueDates.reduce((pre, current) => {
    return pre < current ? current : pre;
  }, dueDates[0]);

  return {
    startDate: earliestStartDate,
    dueDate: latestDueDate,
  };
};

export const getScheduleIsBeforeOrAfter = (
  schedule: Maybe<FeedbackSchedule> | undefined,
  now: Date | undefined = new Date(),
  filterLayers: (layer: Maybe<FeedbackLayer>) => boolean = () => true,
) => {
  // スケジュールがない、レイヤーがない
  const validLayers = takeAwayMaybeElement<FeedbackLayer[]>(schedule?.layers)
    .filter(layer => layer.hasForm)
    .filter(filterLayers);
  if (!schedule || !hasElement(validLayers)) {
    return {
      isPreparing: false,
      isBefore: false,
      isAfter: false,
      isDoing: false,
    };
  }

  // シート準備中
  if (schedule?.status === FeedbackScheduleStatuses.Preparing) {
    return {
      isPreparing: true,
      isBefore: false,
      isAfter: false,
      isDoing: false,
    };
  }

  // 期間を判定
  const { startDate, dueDate } = getEarliestStartDateAndLatestDueDateFromLayers(validLayers);
  const isBefore = startDate ? isBeforeDate(now, startDate) : false;
  const isAfter = dueDate ? !isBefore && isAfterDate(now, dueDate) : false;
  const isDoing = startDate && dueDate ? !isBefore && !isAfter : false;
  return {
    isPreparing: false,
    isBefore,
    isAfter,
    isDoing,
  };
};

export const isItemTemplate = (typename?: string) => {
  return typename ? typename.endsWith('Template') : false;
};

export const isItemEnabled = (item: FeedbackSheetItem, withoutWrapper: boolean) => {
  if (withoutWrapper) {
    return true;
  }

  if (!item) {
    return false;
  }

  const isEnabled = item?.enabled;
  const isCommentEnabled = item?.commentEnabled;
  const isValueEnabled = item?.valueEnabled;

  const isProperty = item.type === FeedbackSheetItemTypes.Property;
  const isListing = item.type === FeedbackSheetItemTypes.Listing;
  const isGuideline = item.type === FeedbackSheetItemTypes.Guideline;
  const isComment = item.type === FeedbackSheetItemTypes.Comment;
  const isMessage = (item as any).type === 'message';

  if (isMessage) {
    return true;
  } else if (isGuideline) {
    return isEnabled;
  } else if (isProperty) {
    return isEnabled;
  } else if (isListing) {
    return isEnabled;
  } else if (isComment) {
    return isEnabled;
  } else {
    return isEnabled && (isCommentEnabled || isValueEnabled);
  }
};

export const isItemRequired = (item: FeedbackSheetItem, withoutWrapper: boolean) =>
  (withoutWrapper ? item.required : item.itemRequired) ? true : false;

export const getAssigneeForm = (
  assignee: User,
  forms: FeedbackSheetForm[] | Maybe<FeedbackSheetForm>[],
) => {
  return forms?.find(f => f?.from?.some(u => u?.id === assignee.id));
};

export const getSheetLayerMemberForm = (
  member: FeedbackSheetLayerMember,
  forms: FeedbackSheetForm[] | Maybe<FeedbackSheetForm>[],
) => {
  return forms?.find(f => f?.id === member.formId);
};

export const getWrapper = (
  assignee: User,
  forms: FeedbackSheetForm[] | Maybe<FeedbackSheetForm>[],
) => {
  const form = forms?.find(f => f?.from?.some(u => u?.id === assignee.id));
  return form?.wrapper;
};

export const isGuidelineItem = (item: any) => item?.type === FeedbackSheetItemTypes.Guideline;
export const isPropertyItem = (item: any) => item?.type === FeedbackSheetItemTypes.Property;

export const isCommentItem = (item: any) =>
  item?.type === FeedbackSheetItemTypes.Comment ||
  (item?.type === FeedbackSheetItemTypes.Objective &&
    item?.elementType === FeedbackSheetItemElementTypes.Comment);

export const isSectionItem = (item: any) =>
  item?.type === FeedbackSheetItemTypes.Section ||
  (item?.type === FeedbackSheetItemTypes.Listing &&
    item?.elementType === FeedbackSheetItemElementTypes.Section);

export const calcItemTemplateWrapperValues = (item: any) => {
  // itemWrapper が defaultWrapper のものかどうか
  const isDefaultWrapper = (item.id && item.id === item.defaultWrapper?.id) || false;
  // defaultWrapper の itemWrapper だったら、 userDefault は false
  const useDefault = isDefaultWrapper ? false : item.useDefault;

  // item.useDefault だったら defaultWrapper を使う
  const wrapper = (useDefault ? item.defaultWrapper : item) || ({} as any);
  // 対象の itemWrapper の valueKey の値
  const showItem = wrapper.showItem; // 項目を有効にする
  const itemEnabled = wrapper.itemEnabled; // 提出フォームで入力
  const commentEnabled = wrapper.commentEnabled;
  const valueEnabled = wrapper.valueEnabled;
  const itemRequired = wrapper.itemRequired;

  const isGuideline = isGuidelineItem(wrapper);
  const isProperty = isPropertyItem(wrapper);
  const isComment = isCommentItem(wrapper);
  const isSection = isSectionItem(wrapper);

  return {
    // デフォルト設定を使用する
    useDefault: {
      checked: useDefault,
      disabled: isDefaultWrapper, // defaultWrapper の場合は disabled
    },
    // 項目を有効にする
    showItem: {
      checked: showItem,
      disabled: useDefault,
    },
    // 提出フォームで入力
    itemEnabled: {
      checked: itemEnabled,
      disabled: useDefault,
    },
    // 評価値の入力
    valueEnabled: {
      // 項目非表示のときは無効
      // Guideline のときは無効
      // Property のときは無効
      // Comment のときは無効
      // コメントと値を両方とも無効にはできない
      checked: (() => {
        if (isGuideline || isProperty || isComment) {
          return false;
        }
        if (!itemEnabled) {
          return false;
        }
        return valueEnabled;
      })(),
      disabled: (() => {
        if (useDefault || !itemEnabled) {
          return true;
        }
        if (isGuideline || isProperty) {
          return true;
        }
        return isComment || !commentEnabled;
      })(),
    },
    // コメントの入力
    commentEnabled: {
      // 項目非表示のときは無効
      // Guideline のときは無効
      // Property のときは無効
      // Comment のときは必須
      // コメントと値を両方とも無効にはできない
      checked: (() => {
        if (isGuideline || isProperty || isSection) {
          return false;
        }
        if (!itemEnabled) {
          return false;
        }
        return isComment || commentEnabled;
      })(),
      disabled: (() => {
        if (useDefault || !itemEnabled) {
          return true;
        }

        if (isGuideline || isProperty || isComment || isSection) {
          return true;
        }
        return !valueEnabled;
      })(),
    },
    // 必須回答
    itemRequired: {
      // 項目非表示のときは無効
      // Guideline のときは無効
      checked: (() => {
        if (isGuideline || isProperty) {
          return false;
        }
        if (!itemEnabled) {
          return false;
        }
        return itemRequired;
      })(),
      disabled: (() => {
        return useDefault || !itemEnabled || isGuideline || isProperty;
      })(),
    },
  };
};

export const toFeedbackSheetItemElementChildren = (
  ie: FeedbackSheetListItemElement | null,
  parentItem: FeedbackSheetItem,
) => {
  const itemTemplateChildren = takeAwayMaybeElement<FeedbackSheetListItemTemplate[]>(
    parentItem?.itemTemplate?.children,
  );

  if (hasElement(ie?.children)) {
    return takeAwayMaybeElement(ie?.children || [])
      .map(i => {
        const iTemplateItem = itemTemplateChildren.find(itci => itci.id === i?.itemTemplateId);

        const parsedItem = toFeedbackSheetItemInput({
          ...i,
          elementType: iTemplateItem?.elementType,
          itemTemplate: iTemplateItem,
        });
        return parsedItem;
      })
      .reduce((acc: FeedbackSheetItemTemplateSaveInput[], i) => {
        const iTemplateItem = i.itemTemplate;

        if (iTemplateItem?.id) {
          const sectionIndex = parseInt(iTemplateItem.id);

          let newSection: any = {
            ...i,
            type: iTemplateItem.type,
            elementType: iTemplateItem?.elementType,
            itemTemplateId: i.itemTemplateId,
          };
          if (iTemplateItem?.elementType === FeedbackSheetItemElementTypes.Grade) {
            newSection = {
              ...newSection,
              ..._parseGradeValuesForSubmission({
                otherOption: newSection.otherOption || '',
                grade: newSection.grade || '',
                allowOtherOptions: iTemplateItem.allowOtherOptions,
                gradeOptions: iTemplateItem.gradeOptions,
                hasPreValue: iTemplateItem.hasPreValue,
              }),
            };
          }

          acc[sectionIndex] = newSection;
        }
        return acc;
      }, []);
  }

  const sectionChildren: FeedbackSheetItemTemplateSaveInput[] = [];
  itemTemplateChildren.forEach(it => {
    if (!it.id) return;

    const sectionIndex = parseInt(it.id);
    const baseSection = {
      type: it.type,
      itemTemplateId: it.id,
    };

    switch (it.type) {
      case FeedbackSheetItemTypes.SingleLine:
        sectionChildren[sectionIndex] = {
          ...baseSection,
          singleLine: ie?.singleLine || '',
        };
        break;

      case FeedbackSheetItemTypes.Grade:
        sectionChildren[sectionIndex] = {
          ...baseSection,
          ..._parseGradeValuesForSubmission({
            otherOption: null,
            grade: ie?.grade || '',
            allowOtherOptions: it?.allowOtherOptions,
            gradeOptions: it?.gradeOptions,
            hasPreValue: it?.hasPreValue,
          }),
        };
        break;
      case FeedbackSheetItemTypes.Listing:
        sectionChildren[sectionIndex] = {
          ...baseSection,
          elements: [],
        };
        break;
      case FeedbackSheetItemTypes.Comment:
        sectionChildren[sectionIndex] = {
          ...baseSection,
          comment: convertValue(ie?.comment?.data || ''),
        };
        break;
    }
  });
  return sectionChildren;
};

export const isSubmittedForm = (form: FeedbackSheetForm) =>
  form?.status === FeedbackSheetFormStatuses.Submitted ||
  form?.status === FeedbackSheetFormStatuses.Published;

export const isLowerLayer = (myLayer: FeedbackLayer, targetLayer: FeedbackLayer) => {
  // layer.type 必須
  if (!myLayer?.type || !targetLayer?.type) return false;
  // target は middle 以外は非対象
  if (targetLayer.type !== FeedbackLayerTypes.Middle) return false;

  // セルフフィードバック： lowerLayer を持たない
  if (myLayer.type === FeedbackLayerTypes.Self) return false;
  // 最終評価： すべての中間評価が lowerLayer
  if (myLayer.type === FeedbackLayerTypes.Final) return true;
  // 中間評価： order が小さいものが lowerLayer
  return (
    ((myLayer as FeedbackMiddleLayer).order || 0) >
    ((targetLayer as FeedbackMiddleLayer)?.order || 0)
  );
};

export const toMiddleLayerLabel = (layer: FeedbackMiddleLayer, fbDict: UseDictionnaryReturn) =>
  i18next.t('{{wMiddleLayerAssignee}}{{no}}', {
    ...fbDict,
    no: layer?.order ? changeOrderToCircledNumber(layer.order!) : '',
  });

export const toMiddleLayerValue = (layer: FeedbackMiddleLayer) => `${layer?.type}:${layer?.order}`;

const feedbackLayerNames: any = (fbDict?: UseDictionnaryReturn | undefined) => ({
  all: {
    label: i18next.t('すべての担当者'),
    sortOrder: 0,
  },
  self: {
    label: i18next.t('{{wSelfLayer}}', fbDict),
    sortOrder: 1,
  },
  middle: {
    label: i18next.t('{{wMiddleLayer}}', fbDict),
    sortOrder: 3,
  },
  middle1: {
    label: i18next.t('{{wMiddleLayer}}①', fbDict),
    sortOrder: 4,
  },
  middle2: {
    label: i18next.t('{{wMiddleLayer}}②', fbDict),
    sortOrder: 5,
  },
  middle3: {
    label: i18next.t('{{wMiddleLayer}}③', fbDict),
    sortOrder: 6,
  },
  final: {
    label: i18next.t('{{wFinalLayer}}', fbDict),
    sortOrder: 7,
  },
  read: {
    label: i18next.t('{{wReadLayer}}', fbDict),
    sortOrder: 8,
  },
  supervise: {
    label: i18next.t('{{wSuperviseLayer}}', fbDict),
    sortOrder: 9,
  },
});
export const toFeedbackLayerKey = (layer: any) => {
  const { type, order } = layer || {};
  if (!type) return '';
  return type === FeedbackLayerTypes.Middle && order > 0 ? `${type}${order}` : type!;
};
export const toFeedbackLayerName = (layer: any, fbDict: UseDictionnaryReturn) => {
  return feedbackLayerNames(fbDict)[toFeedbackLayerKey(layer)]?.label;
};
export const toFeedbackLayerSortOrder = (layer: any) => {
  return feedbackLayerNames()[toFeedbackLayerKey(layer)]?.sortOrder;
};

export const getSheetObjectiveTargetCategories = (
  items: FeedbackSheetItem[],
): ObjectiveCategory[] => {
  const objectiveTypeItems = takeAwayMaybeElement<FeedbackSheetItem[]>(items).filter(
    (item: FeedbackSheetItem) => item?.itemTemplate?.type === FeedbackSheetItemTypes.Objective,
  );

  return objectiveTypeItems.reduce((acc: ObjectiveCategory[], item) => {
    return [
      ...acc,
      ...takeAwayMaybeElement<ObjectiveCategory[]>(
        (item.itemTemplate as FeedbackSheetObjectiveItemTemplate)?.targetCategories,
      ),
    ];
  }, []);
};

export const getSheetObjectiveTargetItemsObjectiveSheetSchedules = (
  items: FeedbackSheetItem[],
): ObjectiveSheetSchedule[] => {
  const objectiveTypeItems = takeAwayMaybeElement<FeedbackSheetItem[]>(items).filter(
    (item: FeedbackSheetItem) => item?.itemTemplate?.type === FeedbackSheetItemTypes.Objective,
  );

  return objectiveTypeItems.reduce((acc: ObjectiveSheetSchedule[], item) => {
    return [
      ...acc,
      ...takeAwayMaybeElement<ObjectiveSheetSchedule[]>(
        (item.itemTemplate as FeedbackSheetObjectiveItemTemplate)?.targetSheetSchedules,
      ),
    ];
  }, []);
};

export const getSheetObjectiveTargetObjectiveSheetSchedules = (sheet: FeedbackSheet): any[] => {
  const template = sheet?.wrappedTemplate || sheet?.schedule?.template;

  return takeAwayMaybeElement<any[]>(template?.targetSheetSchedules);
};

export const reduceSheetItemsOkrs = (
  items: (FeedbackSheetItem & FeedbackSheetObjectiveItem)[],
): Okr[] => {
  return (items || []).reduce((acc: Okr[], item) => {
    const okrs = getActiveOkrs(item.okrs);
    return [...acc, ...okrs];
  }, []);
};

export const getElementOkr = (e: FeedbackSheetObjectiveItemElement) =>
  e.okrType === OkrTypes.KeyResult ? (e.keyResult as KeyResult) : (e.objective as Objective);

export const hasSheetObjectiveWithItemTemplate = (
  items: (FeedbackSheetItem & FeedbackSheetObjectiveItem)[],
) => {
  return items
    .filter(i => i.type === FeedbackSheetItemTypes.Objective)
    .some(item => hasElement(item?.okrs));
};

// すべての required な ObjectiveItem が、対象となる目標を持っているか
export const hasSheetRequiredObjectiveWithItemTemplate = (
  items: (FeedbackSheetItem & FeedbackSheetObjectiveItem)[],
) => {
  return items
    .filter(i => i.type === FeedbackSheetItemTypes.Objective)
    .filter(i => i.required || i.itemRequired) // required の item のみに限定
    .some(item => hasElement(item?.okrs));
};

const _calculateWeightedScore = (args: { weight: number; value: number }) => {
  const value = args?.value || 0;
  const weight = args?.weight || 0;
  return (weight * value) / 100;
};

const _calculateTotalScoreFromElements = (elements: any[]) => {
  return (elements || []).reduce(
    (acc: number, el: any) => acc + _calculateWeightedScore({ weight: el.weight, value: el.value }),
    0,
  );
};

const _calculateTotalScoreFromOkrs = (okrs: (Okr | null)[]) => {
  return (okrs || []).reduce(
    (acc: number, okr: Okr | null) =>
      acc +
      _calculateWeightedScore({
        weight: (okr as Objective | KeyResult)?.weight || 0,
        value: okr?.achievementRate || 0,
      }),
    0,
  );
};

export const calculateTotalScoreForSheetContent = (
  item: FeedbackSheetObjectiveItem | null,
  sourceType: FeedbackSheetTotalScoreSourceTypes,
) => {
  const itemOkrs = takeAwayMaybeElement<Okr[]>(item?.okrs);
  const itemElements = takeAwayMaybeElement<FeedbackSheetObjectiveItemElement[]>(
    item?.elements,
  ).map(e => {
    const okr = getElementOkr(e as FeedbackSheetObjectiveItemElement);

    if (isWeightInputReadOnly(okr)) {
      return {
        ...e,
        weight: okr.weight,
      };
    }
    return e;
  });

  if (sourceType === FeedbackSheetTotalScoreSourceTypes.Objective) {
    const elementOkrs = itemElements.length
      ? itemElements.map(e => {
          return getElementOkr(e as FeedbackSheetObjectiveItemElement);
        })
      : null;

    return _calculateTotalScoreFromOkrs(elementOkrs || itemOkrs);
  }

  return _calculateTotalScoreFromElements(itemElements);
};

export const isWeightInputReadOnly = (okr: Okr) => !!(okr as Objective)?.sheetItemTemplate;

const mergeBothFeedbackSheetObjectiveItemElementsAndOkrs = (
  item: FeedbackSheetObjectiveItem,
  sheetItem: FeedbackSheetItem & FeedbackSheetObjectiveItem,
) => {
  const iTemplate = sheetItem?.itemTemplate;
  const totalScoreSourceType = iTemplate?.totalScoreSourceType;
  const itemElementType = 'FeedbackSheetObjectiveItemElement';

  const groupElementOkrs = [
    ...takeAwayMaybeElement<FeedbackSheetObjectiveItemElement[]>(item?.elements),
    ...takeAwayMaybeElement<Okr[]>(sheetItem?.okrs),
  ].reduce(
    (
      acc: Record<string, any>,
      elementOkr:
        | FeedbackSheetObjectiveItemElement
        | (Okr & {
            objective?: Objective;
          }),
    ) => {
      const __typename = (elementOkr as any).__typename;
      const okrId = __typename === itemElementType ? elementOkr?.objective?.id! : elementOkr.id!;
      acc[okrId] = {
        ...acc[okrId],
        [__typename]: elementOkr,
      };

      return acc;
    },
    {},
  );

  return takeAwayMaybeElement<any[]>(Object.keys(groupElementOkrs))?.reduce(
    (acc: any[], okrId: string) => {
      const groupElementOkr = groupElementOkrs[okrId];
      const objective = groupElementOkr['Objective'] as Objective;
      const element = groupElementOkr[itemElementType];

      if (totalScoreSourceType === FeedbackSheetTotalScoreSourceTypes.Objective && objective) {
        acc.push({
          ...objective,
          weight: objective?.weight || 0,
          value: objective?.achievementRate || 0,
        });
        return acc;
      }

      if (totalScoreSourceType === FeedbackSheetTotalScoreSourceTypes.Item && element) {
        acc.push(element);
        return acc;
      }

      return acc;
    },
    [],
  );
};

export const calculateTotalScoreFromValueAndSheetItem = (
  item: FeedbackSheetObjectiveItem | null | undefined,
  sheetItem: (FeedbackSheetItem & FeedbackSheetObjectiveItem) | null | undefined,
) => {
  if (!item || !sheetItem) return 0;

  const elements = mergeBothFeedbackSheetObjectiveItemElementsAndOkrs(item, sheetItem);
  return _calculateTotalScoreFromElements(elements);
};

export const calculateGradeScore = (args: { formula?: string; score?: number; grade?: string }) => {
  const { formula, score = 0, grade = '' } = args;
  if (!formula) return score;

  try {
    // eslint-disable-next-line no-new-func
    return new Function('score', 'grade', formula)(score || 0, grade || '');
  } catch (e) {
    return score;
  }
};

const _calculateTotalWeightFromElements = (elements: any[]) => {
  return (elements || []).reduce((acc: number, el: any) => acc + parseFloat(el?.weight || `0`), 0);
};

export const calculateTotalWeightFromValueAndSheetItem = (
  item: FeedbackSheetObjectiveItem | null | undefined,
  sheetItem: (FeedbackSheetItem & FeedbackSheetObjectiveItem) | null | undefined,
) => {
  if (!item || !sheetItem) return 0;

  const elements = mergeBothFeedbackSheetObjectiveItemElementsAndOkrs(item, sheetItem);
  return _calculateTotalWeightFromElements(elements);
};

// export const calculateTotalWeightFromOkrs = (okrs: (Okr | null)[]) => {
//   return (okrs || []).reduce((acc: number, el: any) => acc + parseFloat(el?.weight || `0`), 0);
// };

// export const calculateTotalWeight = (
//   item: FeedbackSheetObjectiveItem | null,
//   sourceType: FeedbackSheetTotalScoreSourceTypes,
// ) => {
//   // FeedbackSheetTotalScoreSourceTypes が Objective -> OKR の達成度を使用
//   if (sourceType === FeedbackSheetTotalScoreSourceTypes.Objective) {
//     return calculateTotalWeightFromOkrs(item?.okrs || []);
//   }
//   // その他 -> 回答項目の value を使用
//   return _calculateTotalWeightFromElements(item?.elements || []);
// };

export const isMeAuthor = (item: any, me: User) => {
  const authors = (item?.from || []).length > 1 ? item?.from || [] : [item?.user];
  return authors.some((author: User) => author?.id === me?.id);
};

export const createDefaultLayers = (
  feedback: Partial<Feedback>,
  fbDict: UseDictionnaryReturn | undefined,
): FeedbackDataSheetLayer[] => {
  const { enableMiddleFeedback, middleLayerType } = feedback;
  let incId = 0;

  const layers: FeedbackDataSheetLayer[] = [
    {
      id: `${incId++}`,
      type: FeedbackLayerTypes.Self,
      label: i18next.t('{{wSelfLayerAssignee}}', fbDict),
      hide: false,
    },
  ];

  if (enableMiddleFeedback) {
    if (middleLayerType === FeedbackMiddleLayerTypes.Parallel) {
      layers.push({
        id: `${incId++}`,
        type: FeedbackLayerTypes.Middle,
        label: i18next.t('{{wMiddleLayerAssignee}}', fbDict),
        order: 0,
        hide: false,
      });
    }

    if (middleLayerType === FeedbackMiddleLayerTypes.Serial) {
      layers.push({
        id: `${incId++}`,
        type: FeedbackLayerTypes.Middle,
        label: i18next.t('{{wMiddleLayerAssignee}}①', fbDict),
        order: 1,
        hide: false,
      });

      layers.push({
        id: `${incId++}`,
        type: FeedbackLayerTypes.Middle,
        label: i18next.t('{{wMiddleLayerAssignee}}②', fbDict),
        order: 2,
        hide: false,
      });

      layers.push({
        id: `${incId++}`,
        type: FeedbackLayerTypes.Middle,
        label: i18next.t('{{wMiddleLayerAssignee}}③', fbDict),
        order: 3,
        hide: false,
      });
    }
  }

  layers.push({
    id: `${incId++}`,
    type: FeedbackLayerTypes.Final,
    label: i18next.t('{{wFinalLayerAssignee}}', fbDict),
    hide: false,
  });

  layers.push({
    id: `${incId++}`,
    type: FeedbackLayerTypes.Read,
    label: i18next.t('{{wReadLayerAssignee}}', fbDict),
    hide: false,
  });

  layers.push({
    id: `${incId++}`,
    type: FeedbackLayerTypes.Supervise,
    label: i18next.t('{{wSuperviseLayerAssignee}}', fbDict),
    hide: false,
  });

  return layers;
};

export interface CreateSheetItemProps {
  type: FeedbackLayerTypes;
  label: string;
  order?: number;
}

export const createDefaultSheets = (
  feedback: Partial<Feedback>,
  fbDict: UseDictionnaryReturn,
): CreateSheetItemProps[] => {
  const { enableMiddleFeedback, middleLayerType } = feedback;

  const sheets: CreateSheetItemProps[] = [
    {
      type: FeedbackLayerTypes.Self,
      label: i18next.t('{{wSelfLayer}}', fbDict),
    },
  ];

  if (enableMiddleFeedback) {
    if (middleLayerType === FeedbackMiddleLayerTypes.Parallel) {
      sheets.push({
        type: FeedbackLayerTypes.Middle,
        label: i18next.t('{{wMiddleLayer}}', fbDict),
        order: 0,
      });
    }

    if (middleLayerType === FeedbackMiddleLayerTypes.Serial) {
      sheets.push({
        type: FeedbackLayerTypes.Middle,
        label: i18next.t('{{wMiddleLayer}}①', fbDict),
        order: 1,
      });

      sheets.push({
        type: FeedbackLayerTypes.Middle,
        label: i18next.t('{{wMiddleLayer}}②', fbDict),
        order: 2,
      });

      sheets.push({
        type: FeedbackLayerTypes.Middle,
        label: i18next.t('{{wMiddleLayer}}③', fbDict),
        order: 3,
      });
    }
  }

  sheets.push({
    type: FeedbackLayerTypes.Final,
    label: i18next.t('{{wFinalLayer}}', fbDict),
  });

  return sheets;
};

interface SetLayersFromParamsParams {
  enableMiddleFeedback: boolean;
  middleLayerType: FeedbackMiddleLayerTypes;
  middleCount: number;
}
export const setMiddleFeedbackLayerVisibilityFromParams = (
  layers: FeedbackDataSheetLayer[],
  params: SetLayersFromParamsParams,
): FeedbackDataSheetLayer[] => {
  const { middleLayerType, enableMiddleFeedback, middleCount = 3 } = params;
  return layers.map((layer: FeedbackDataSheetLayer) => {
    if (layer.type !== FeedbackLayerTypes.Middle) {
      return {
        ...layer,
        hide: false,
      };
    }

    if (!enableMiddleFeedback) {
      return {
        ...layer,
        hide: true,
      };
    }

    if (middleLayerType === FeedbackMiddleLayerTypes.Parallel) {
      return {
        ...layer,
        hide: false,
      };
    }

    return {
      ...layer,
      hide: layer?.order! > middleCount,
    };
  });
};

export const hasLayerAccessToLayer = (
  feedback: Partial<Feedback>,
  fromLayer: Partial<FeedbackDataSheetLayer>,
  toLayer: Partial<FeedbackDataSheetLayer>,
): boolean | 'onlyComment' | 'onlyMine' | 'onlyRating' | 'onlyRatingAndComment' => {
  switch (fromLayer.type) {
    //被評価者
    case FeedbackLayerTypes.Self:
      switch (toLayer.type) {
        //被評価者 to 被評価者
        case FeedbackLayerTypes.Self:
          return feedback.enableSelfFeedback || false;

        //被評価者 to 中間評価者
        case FeedbackLayerTypes.Middle:
          if (!feedback.enableMiddleFeedback) {
            return false;
          }

          if (feedback.publishMiddleFeedbackRating && feedback.publishMiddleFeedback) {
            return 'onlyRatingAndComment';
          } else if (feedback.publishMiddleFeedbackRating) {
            return 'onlyRating';
          } else if (feedback.publishMiddleFeedback) {
            return 'onlyComment';
          }

          return false;

        //被評価者 to 最終評価者
        case FeedbackLayerTypes.Final:
          return feedback.enableMiddleFeedback ? true : feedback.publishSenderFeedback || false;

        default:
          return false;
      }

    //中間評価者
    case FeedbackLayerTypes.Middle:
      if (!feedback.enableMiddleFeedback) {
        return false;
      }

      switch (toLayer.type) {
        //中間評価者 to 被評価者
        case FeedbackLayerTypes.Self:
          return (feedback.enableSelfFeedback && feedback.publishSelfToMiddle) || false;

        //中間評価者 to 中間評価者
        case FeedbackLayerTypes.Middle:
          if (feedback.senderPublicationRange === FeedbackSenderPublicationRanges.All) {
            return true;
          }

          if (feedback.middleLayerType === FeedbackMiddleLayerTypes.Parallel) {
            return 'onlyMine';
          }

          // orderが使われているロジックはここから
          if (!isNumber(fromLayer.order) || !isNumber(toLayer.order)) {
            return false;
          }
          if (fromLayer.order === toLayer.order) {
            return true;
          }
          if (feedback.senderPublicationRange === FeedbackSenderPublicationRanges.None) {
            return false;
          }
          if (feedback.senderPublicationRange === FeedbackSenderPublicationRanges.Hierarchy) {
            return fromLayer?.order! > toLayer?.order!;
          }

          return false;

        //中間評価者 to 最終評価者
        case FeedbackLayerTypes.Final:
          if (feedback.senderPublicationRange === FeedbackSenderPublicationRanges.All) {
            return true;
          }
          return false;

        default:
          return false;
      }

    //最終評価者
    case FeedbackLayerTypes.Final:
      switch (toLayer.type) {
        //最終評価者 to 被評価者
        case FeedbackLayerTypes.Self:
          if (!feedback.enableSelfFeedback) {
            return false;
          }

          if (!feedback.publishSenderFeedback) {
            //評価者の回答を被評価者に公開する
            //この項目をOFFにすると、評価者の回答は被評価者には（被評価者の回答も評価者には）公開されません。
            return false;
          }

          return true;

        //最終評価者 to 中間評価者
        case FeedbackLayerTypes.Middle:
          return feedback.enableMiddleFeedback || false;

        //最終評価者 to 最終評価者
        case FeedbackLayerTypes.Final:
          if (feedback.enableMiddleFeedback) {
            return true;
          }
          return 'onlyMine';

        default:
          return false;
      }

    //閲覧者・管理者
    case FeedbackLayerTypes.Read:
    case FeedbackLayerTypes.Supervise:
      switch (toLayer.type) {
        //閲覧者・管理者 to 被評価者
        case FeedbackLayerTypes.Self:
          return feedback.enableSelfFeedback || false;

        //閲覧者・管理者 to 中間評価者
        case FeedbackLayerTypes.Middle:
          return feedback.enableMiddleFeedback || false;

        //閲覧者・管理者 to 最終評価者
        case FeedbackLayerTypes.Final:
          return true;

        default:
          return false;
      }

    default:
      return false;
  }
};

export const isLayerAllowMultiAssignee = (
  layerType: FeedbackLayerTypes,
  middleLayerType: FeedbackMiddleLayerTypes,
) => {
  return (
    layerType === FeedbackLayerTypes.Read ||
    layerType === FeedbackLayerTypes.Final ||
    (layerType === FeedbackLayerTypes.Middle &&
      middleLayerType === FeedbackMiddleLayerTypes.Parallel)
  );
};

export const transformFeedbackSheetToFeedbackDataSheet = (
  sheet: Partial<FeedbackSheet>,
): FeedbackDataSheet => {
  return {
    id: sheet.id!,
    receiverId: sheet.receiver?.id!,
    enableSelfFeedback: sheet.enableSelfFeedback || false,
    layers: takeAwayMaybeElement<FeedbackDataSheetLayer[]>(sheet.layers),
    forms: takeAwayMaybeElement<FeedbackSheetForm[]>(sheet.forms),
    formsForHr: takeAwayMaybeElement<FeedbackSheetForm[]>(sheet.formsForHr),
  };
};

interface SheetFormUserForm {
  user: User;
  form: FeedbackSheetForm;
}
export interface FeedbackSheetMemberStatus {
  id: string;
  sheet: FeedbackSheet;
  layer: FeedbackSheetLayer;
  form?: FeedbackSheetForm | undefined;
  status?: FeedbackSheetLayerMemberStatuses | null | undefined;
  user: User;
  requester: Maybe<User> | undefined;
  requestedData: Maybe<FeedbackSheetLayerRequestedData> | undefined;
  requesterType: Maybe<FeedbackLayerTypes> | undefined;
  isRequested: boolean;
  isRequesting: boolean;
  isCancelled: boolean;
  isRejected: boolean;
  isVoluntary: boolean;
  rejectionMessage?: Maybe<string> | undefined;
  rejectionReason?: Maybe<string> | undefined;
}

interface GetMembersFromFeedbackSheetFilters {
  layerTypes?: FeedbackLayerTypes[];
  layerOrder?: Maybe<number>;
  middleLayerType?: Maybe<FeedbackMiddleLayerTypes>;
  isRequested?: boolean;
  isVoluntary?: boolean;
  statuses?: FeedbackSheetLayerMemberStatuses[];
  isHr?: boolean;
  actorRequesterType?: Maybe<FeedbackLayerTypes> | undefined;
}

interface ReduceSheetLayerMember {
  form: FeedbackSheetForm | undefined;
  layer: FeedbackSheetLayer;
  member: FeedbackSheetLayerMember;
}

export const getMembersFromFeedbackSheet = (
  sheet: FeedbackSheet,
  filters: GetMembersFromFeedbackSheetFilters,
): FeedbackSheetMemberStatus[] => {
  const {
    layerTypes = [
      FeedbackLayerTypes.Self,
      FeedbackLayerTypes.Middle,
      FeedbackLayerTypes.Final,
      FeedbackLayerTypes.Read,
    ],
    layerOrder = null,
    middleLayerType = FeedbackMiddleLayerTypes.Serial,
    statuses = [
      FeedbackSheetLayerMemberStatuses.Active,
      FeedbackSheetLayerMemberStatuses.Canceled,
      FeedbackSheetLayerMemberStatuses.Rejected,
      FeedbackSheetLayerMemberStatuses.Requested,
    ],
    isRequested,
    isVoluntary,
    isHr = false,
    actorRequesterType,
  } = filters;

  const forms = takeAwayMaybeElement<FeedbackSheetForm[]>(
    isHr ? sheet.formsForHr : sheet.forms,
  ).reduce((acc: SheetFormUserForm[], form) => {
    return [
      ...acc,
      ...takeAwayMaybeElement<User[]>(form.from).map(user => ({
        user,
        form,
      })),
    ];
  }, []);

  const sheetLayers = takeAwayMaybeElement<FeedbackSheetLayer[]>(sheet.layers).filter(layer => {
    if (layer.type === FeedbackLayerTypes.Middle) {
      if (layerOrder !== undefined && layerOrder !== null) {
        if (middleLayerType === FeedbackMiddleLayerTypes.Serial && layer.order !== layerOrder) {
          return false;
        }
      }
    }
    return layer.type && layerTypes.includes(layer.type);
  });

  const sheetLayerMembers = sheetLayers
    .reduce((acc: ReduceSheetLayerMember[], layer) => {
      return [
        ...acc,
        ...takeAwayMaybeElement<ReduceSheetLayerMember[]>(
          takeAwayMaybeElement<FeedbackSheetLayerMember[]>(layer.members)
            .filter(member => {
              if (!member.assignee) return false;

              if (
                member.requesterType &&
                actorRequesterType &&
                member.requesterType !== actorRequesterType
              ) {
                return false;
              }

              return true;
            })
            .map(member => {
              const userForm = forms?.find(f => f.form.id === member.formId);
              return {
                form: userForm?.form,
                member,
                layer,
              };
            }),
        ),
      ];
    }, [])
    .filter(layer => {
      let show = true;

      if (isVoluntary !== undefined) {
        if (layer.member.isVoluntary !== isVoluntary) {
          show = false;
        }
      }

      if (isRequested !== undefined) {
        if (layer.member.isRequested !== isRequested) {
          show = false;
        }
      }
      return show;
    });

  return sheetLayerMembers
    .map(sheetLayerMember => {
      const { member, layer, form: sheetLayerMemberForm } = sheetLayerMember;

      const userForm = forms.find(f => f.user.id === member.assignee?.id);
      const form = sheetLayerMemberForm || userForm?.form;

      return {
        id: member.id,
        user: member?.assignee!,
        requester: member?.requester,
        requesterType: member?.requesterType,
        sheet,
        layer,
        form,
        status: member.status,
        requestedData: member.requestedData,
        isRequested: (member.isRequested && !!form) || false,
        isRequesting: member.status === FeedbackSheetLayerMemberStatuses.Requested,
        isRejected: member.status === FeedbackSheetLayerMemberStatuses.Rejected,
        isCancelled: member.status === FeedbackSheetLayerMemberStatuses.Canceled,
        isVoluntary: member.isVoluntary || false,
        rejectionMessage: member?.rejectionMessage,
        rejectionReason: member?.rejectionReason,
      };
    })
    .filter(sheetLayerMember => statuses.includes(sheetLayerMember.status!))
    .sort(comparatorFeedbackSheetMember);
};

export const findTargetLayer = (layers: FeedbackLayerData[], layerType: string) => {
  return layerType === 'middle'
    ? layers.find(layer => layer.type === FeedbackLayerTypes.Middle && layer.order === 0)
    : layerType.startsWith('middle')
      ? layers.find(
          layer =>
            layer.type === FeedbackLayerTypes.Middle &&
            layer.order === Number.parseInt(layerType.substring(6)),
        )
      : layers.find(layer => layer.type === (layerType as FeedbackLayerTypes));
};

export const isFeedbackRequestDateRange = (schedule: FeedbackSchedule) => {
  return (
    schedule?.request === null ||
    schedule?.request?.startAt === null ||
    isSameOrAfter(new Date(), schedule?.request?.startAt)
  );
};

export const getLayersMember = (sheet: FeedbackSheet, userId: string) => {
  const members = takeAwayMaybeElement<FeedbackSheetLayer[]>(sheet?.layers).reduce(
    (acc: FeedbackSheetLayerMember[], layer: FeedbackSheetLayer) => {
      if (hasElement(layer.members)) {
        return [...acc, ...takeAwayMaybeElement<FeedbackSheetLayerMember[]>(layer.members)];
      }
      return acc;
    },
    [],
  );

  return members.find(m => m.assignee?.id === userId);
};

export const groupSheetsByFeedback = (sheets: FeedbackSheet[]) =>
  sheets.reduce((acc: any[], sheet: FeedbackSheet) => {
    if (!sheet) {
      return acc;
    }
    const index = acc.findIndex(i => i?.person?.id === sheet?.schedule?.feedbackId);
    if (index > -1) {
      acc[index].sheets.push(sheet);
    } else {
      acc.push({
        feedback: sheet?.schedule?.feedback,
        sheets: [sheet],
      });
    }
    return acc;
  }, []);

export const getSubmittableSheets = (feedback: Feedback, sheets: FeedbackSheet[]) =>
  sheets.filter(sheet => {
    const now = new Date();

    if (
      sheet?.schedule?.request?.startAt &&
      !isBeforeDate(now, sheet?.schedule?.request?.startAt)
    ) {
      return true;
    }

    if (feedback?.enableSelfFeedback) {
      const { isDoing, isAfter } = getScheduleIsBeforeOrAfter(
        sheet?.schedule,
        new Date(),
        layer => layer?.type === FeedbackLayerTypes.Self,
      );
      return isDoing || isAfter;
    }

    return true;
  });

export const getExcludeMemberIdsMap = (
  me: User,
  feedback: Feedback,
  sheets: FeedbackSheet[],
): Record<string, string[]> => {
  const map: Record<string, string[]> = {};

  sheets.forEach(s => {
    const actorLayer = getMyLayer(s, me.id);

    const senders = getMembersFromFeedbackSheet(s, {
      layerTypes: [FeedbackLayerTypes.Self, FeedbackLayerTypes.Middle, FeedbackLayerTypes.Final],
      statuses: [
        FeedbackSheetLayerMemberStatuses.Active,
        FeedbackSheetLayerMemberStatuses.Requested,
      ],
      middleLayerType: feedback?.middleLayerType!,
      actorRequesterType: actorLayer?.type,
    });

    const excludeMemberIds = getArrayIds(
      senders.map((sender: FeedbackSheetMemberStatus) => sender.user),
    );
    excludeMemberIds.push(me.id);

    map[s.id] = excludeMemberIds;
  });
  return map;
};

const _findWeight = (sheet: FeedbackSheet | null, itemTemplateId: string, okr: Okr | null) => {
  const objectiveWeights = takeAwayMaybeElement<ObjectiveWeight[]>(sheet?.weights);
  return !!okr && okr.okrType === OkrTypes.Objective
    ? objectiveWeights.find(
        ow => ow?.itemTemplateId === itemTemplateId && ow?.objectiveId === okr.id,
      )?.weight
    : 0;
};

export const _generateElementChildrenForSubmissionInitialValues = (
  ie: FeedbackSheetListItemElement | null,
  parentItem: FeedbackSheetItem,
  props: {
    myForm: FeedbackSheetForm | null;
    sheet: FeedbackSheet | null;
    visibleOkrs: Okr[];
    isSelfFeedback: boolean;
  },
): FeedbackSheetItemTemplateSaveInput[] => {
  const { myForm, sheet, visibleOkrs = [], isSelfFeedback } = props;

  const itemTemplateChildren = takeAwayMaybeElement<FeedbackSheetListItemTemplate[]>(
    parentItem?.itemTemplate?.children,
  );

  const onPreExistingChildren = !hasElement(ie?.children)
    ? []
    : (ie?.children || [])
        .map(i => {
          const iItemTemplate = itemTemplateChildren.find(itci => itci.id === i?.itemTemplateId);

          const parsedItem = _parseFeedbackSheetItemForSubmissionInitialValues(
            {
              ...i,
              elementType: iItemTemplate?.elementType,
              itemTemplate: iItemTemplate,
            },
            {
              myForm,
              sheet,
              visibleOkrs,
              isSelfFeedback,
            },
          );

          return parsedItem;
        })
        .reduce((acc: any[], i) => {
          const iItemTemplate = i.itemTemplate;
          if (iItemTemplate?.id) {
            const sectionIndex = parseInt(iItemTemplate.id);
            acc[sectionIndex] = {
              ...i,
              type: iItemTemplate.type,
              elementType: iItemTemplate?.elementType,
              itemTemplate: iItemTemplate,
              itemTemplateId: i.itemTemplateId,
              formId: myForm?.id,
              gradeValue: i?.gradeValue && !isUndefined(i?.gradeValue) ? `${i?.gradeValue}` : '',
            };

            if (iItemTemplate.elementType === FeedbackSheetItemElementTypes.Grade) {
              acc[sectionIndex] = {
                ...acc[sectionIndex],
                ..._parseGradeValuesForSubmission(i),
              };
            }
          }
          return acc;
        }, []);

  const createOrMergePreExistingChildren = (() => {
    const sectionChildren: any[] = [];
    itemTemplateChildren.forEach(it => {
      if (!it.id) return;

      const sectionIndex = parseInt(it.id);

      const preExistingChild = onPreExistingChildren[sectionIndex];
      if (preExistingChild) {
        sectionChildren[sectionIndex] = preExistingChild;
        return;
      }

      const baseSection = {
        type: it.type,
        itemTemplate: it,
        itemTemplateId: it.id,
        formId: myForm?.id,
      };

      switch (it.type) {
        case FeedbackSheetItemTypes.SingleLine:
          sectionChildren[sectionIndex] = {
            ...baseSection,
            singleLine: ie?.singleLine || '',
          };
          break;

        case FeedbackSheetItemTypes.Grade:
          sectionChildren[sectionIndex] = {
            ...baseSection,
            ..._parseGradeValuesForSubmission(it),
          };
          break;
        case FeedbackSheetItemTypes.Listing:
          sectionChildren[sectionIndex] = {
            ...baseSection,
            elements: [],
          };
          break;
        case FeedbackSheetItemTypes.Comment:
          sectionChildren[sectionIndex] = {
            ...baseSection,
            comment: convertValue(ie?.comment?.data || ''),
          };
          break;
      }
    });
    return sectionChildren;
  })();

  return createOrMergePreExistingChildren;
};

const _parseFeedbackSheetItemElementForSubmissionInitialValues = (
  ie: FeedbackSheetListItemElement,
  parentItem: FeedbackSheetItem,
  props: {
    myForm: FeedbackSheetForm | null | undefined;
    sheet: FeedbackSheet | null;
    visibleOkrs: Okr[];
    isSelfFeedback: boolean;
  },
): any => {
  const { myForm, sheet, isSelfFeedback, visibleOkrs = [] } = props;

  let payload: any = {
    type: ie.type,
    id: ie.id,
    itemId: ie.itemId,
    hasPreValue: ie.hasPreValue,
    links: takeAwayMaybeElement<ReferenceLink[]>((ie as any).links).map(link => ({
      title: link.title || '',
      url: link.url || '',
    })),
  };

  switch (ie.type) {
    case FeedbackSheetItemElementTypes.SingleLine:
      payload = {
        ...payload,
        singleLine: ie.singleLine,
      };
      break;
    case FeedbackSheetItemElementTypes.Section:
      payload = {
        ...payload,
        children: _generateElementChildrenForSubmissionInitialValues(ie, parentItem, {
          myForm,
          sheet,
          visibleOkrs,
          isSelfFeedback,
        }),
      };
      break;
  }

  return payload;
};

export const _parseGradeValuesForSubmission = (
  item: Partial<
    FeedbackSheetGradeItem | FeedbackSheetObjectiveItem | FeedbackSheetObjectiveItemElement
  >,
) => {
  const itemGradeValue = item.grade;
  const gradeOptions = takeAwayEmptyElement(item?.gradeOptions);
  const otherOption = item?.otherOption;
  const hasPreValue = item.hasPreValue;
  const allowOtherOptions = item?.allowOtherOptions || false;

  const gradeValue = (() => {
    if (allowOtherOptions && !gradeOptions.length) {
      return 'other';
    }
    if (!itemGradeValue && otherOption) {
      return 'other';
    }
    return itemGradeValue || null;
  })();

  return {
    gradeValue,
    grade: gradeValue,
    gradeOptions,
    otherOption,
    allowOtherOptions,
    hasPreValue,
  };
};
export const _parseFeedbackSheetItemForSubmissionInitialValues = (
  item: FeedbackSheetAllTypeItem,
  props: {
    myForm: FeedbackSheetForm | undefined | null;
    sheet: FeedbackSheet | null;
    visibleOkrs: Okr[];
    isSelfFeedback: boolean;
  },
): any => {
  const { myForm, sheet, visibleOkrs = [], isSelfFeedback } = props;

  const itemTemplate = item.itemTemplate;

  let payload: any = {
    id: item.id,
    formId: item.formId || myForm?.id,
    itemTemplateId: item.itemTemplateId || itemTemplate?.id,
    itemTemplate,
    type: item.type,
    enabled: item.enabled,
    commentEnabled: item.commentEnabled,
    valueEnabled: item.valueEnabled,
    itemRequired: item.required,
    comment: convertValue((item as FeedbackSheetCommentItem)?.comment?.data || ''),
    externalLinks: takeAwayEmptyElement<string[]>(
      (item as FeedbackSheetObjectiveItem)?.externalLinks,
    ),
    descriptionLinks: takeAwayEmptyElement<ReferenceLinkInput[]>(
      (item as FeedbackSheetObjectiveItem)?.descriptionLinks,
    ),
    __typename: (item as any).__typename,
  };

  const userPropertyItem = item as FeedbackSheetPropertyItem;
  const numberItem = item as FeedbackSheetNumberItem;
  const gradeItem = item as FeedbackSheetGradeItem;
  const singleLineItem = item as FeedbackSheetSingleLineItem;
  const objectiveItem = item as FeedbackSheetObjectiveItem;
  const objectiveItemTemplate = itemTemplate as FeedbackSheetObjectiveItemTemplate;
  const listItem = item as FeedbackSheetListItem;

  switch (item.type) {
    case FeedbackSheetItemTypes.Guideline:
    case FeedbackSheetItemTypes.Comment:
      break;
    case FeedbackSheetItemTypes.Property:
      payload = {
        ...payload,
        userProperty: userPropertyItem.userProperty ?? null,
      };
      break;
    case FeedbackSheetItemTypes.Number: {
      payload = {
        ...payload,
        value: numberItem.value,
      };
      break;
    }
    case FeedbackSheetItemTypes.Grade: {
      payload = {
        ...payload,
        ..._parseGradeValuesForSubmission(gradeItem),
      };
      break;
    }
    case FeedbackSheetItemTypes.SingleLine: {
      payload = {
        ...payload,
        singleLine: singleLineItem.singleLine,
      };
      break;
    }
    case FeedbackSheetItemTypes.Objective: {
      let overallPayload: any = {};
      if (objectiveItemTemplate.enableOverallEvaluation) {
        switch (objectiveItem.elementType) {
          case FeedbackSheetItemElementTypes.Number:
            overallPayload = {
              value: objectiveItem.value,
            };
            break;
          case FeedbackSheetItemElementTypes.Grade:
            overallPayload = {
              ..._parseGradeValuesForSubmission(objectiveItem),
            };
            break;
          default:
            break;
        }
      }

      const itemOkrs: Okr[] = (() => {
        return getActiveOkrs(objectiveItem?.okrs);
      })();

      // elements を再構成する
      //eslint-disable-next-line
      const newElements = (() => {
        if (!objectiveItemTemplate?.enableIndividualEvaluation) {
          return [];
        }

        const elements = takeAwayEmptyElement<FeedbackSheetObjectiveItemElement[]>(
          objectiveItem?.elements,
        );

        // 削除されたものは取り除く
        let newElements = takeAwayMaybeElement<FeedbackSheetObjectiveItemElement[]>(elements)
          .filter(e => {
            const eOkr = getElementOkr(e);
            return (
              !!eOkr &&
              eOkr.status !== OkrStatuses.Deleted &&
              itemOkrs.some(tOkr => isSameOkr(tOkr, eOkr))
            );
          })
          .map(e => {
            const eOkr = getElementOkr(e);
            return {
              ...e,
              comment: convertValue(e?.comment?.data!),
              weight: isWeightInputReadOnly(eOkr)
                ? eOkr.weight || 0
                : e?.weight === undefined || e?.weight === null
                  ? _findWeight(sheet, objectiveItem.itemTemplateId, e?.objective || null) ||
                    e?.objective?.weight ||
                    0
                  : e?.weight,
            };
          });

        itemOkrs.forEach(okr => {
          if (
            !newElements.map(e => getElementOkr(e)).some(eOkr => !!eOkr && isSameOkr(eOkr, okr))
          ) {
            newElements.push({
              id: '-1',
              okrType: okr.okrType,
              objectiveId: okr.okrType === OkrTypes.Objective ? okr.id : null,
              objective: okr.okrType === OkrTypes.Objective ? okr : null,
              keyResultId: okr.okrType === OkrTypes.KeyResult ? okr.id : null,
              keyResult: okr.okrType === OkrTypes.KeyResult ? okr : null,
              type: objectiveItemTemplate.elementType!,
              comment: convertValue(''),
              weight:
                _findWeight(sheet, objectiveItem.itemTemplateId, okr) ||
                (okr as Objective)?.weight ||
                0,
            });
          }
        });

        if (objectiveItem.elementType === FeedbackSheetItemElementTypes.Grade) {
          newElements = newElements.map(e => {
            return {
              ...(e as any),
              ..._parseGradeValuesForSubmission({
                otherOption: e.otherOption,
                grade: e.grade,
                allowOtherOptions: objectiveItemTemplate.allowOtherOptions,
                gradeOptions: objectiveItemTemplate.gradeOptions,
                hasPreValue: objectiveItemTemplate.hasPreValue,
              }),
            };
          });
        }
        return sortWithoutBreaking(newElements, comparatorObjectiveOrder);
      })();

      const newOkrs = (() => {
        const elements = takeAwayEmptyElement<FeedbackSheetObjectiveItemElement[]>(
          objectiveItem?.elements,
        );
        const newOkrs = takeAwayMaybeElement<Okr[]>(elements?.map(e => getElementOkr(e))).map(
          eOkr => {
            const itemOkr = itemOkrs.find(okr => isSameOkr(eOkr, okr));
            return itemOkr || eOkr;
          },
        );

        itemOkrs.forEach(itemOkr => {
          if (!newOkrs.some(newOkr => isSameOkr(newOkr, itemOkr))) {
            newOkrs.push(itemOkr);
          }
        });

        return getActiveOkrs(newOkrs);
      })();

      payload = {
        ...payload,
        ...overallPayload,
        hasPreValue: objectiveItem.hasPreValue,
        elementType: objectiveItem.elementType,
        elements: newElements,
        isSelfFeedback,
        okrs: newOkrs,
      };

      if (objectiveItem.elementType === FeedbackSheetItemElementTypes.Grade) {
        payload = {
          ...payload,
          ..._parseGradeValuesForSubmission(objectiveItem),
        };
      }
      break;
    }
    case FeedbackSheetItemTypes.Listing: {
      payload = {
        ...payload,
        hasPreValue: listItem.hasPreValue,
        elementType: listItem.elementType,
        elements: takeAwayMaybeElement<any[]>(
          (() => {
            const elements = listItem.elements;
            if (hasElement(elements)) {
              return (elements || []).map(e => ({
                ...e,
                itemId: item.id,
                type: listItem.elementType,
              }));
            }
            return [
              {
                itemId: item.id,
                type: listItem.elementType,
              },
            ];
          })(),
        ).map(itemElement =>
          _parseFeedbackSheetItemElementForSubmissionInitialValues(itemElement, listItem, {
            sheet,
            myForm,
            visibleOkrs,
            isSelfFeedback,
          }),
        ),
      };
      break;
    }
  }

  return payload;
};

export const convertFeedbackSheetItemsToSubmissionInitialValues = (
  items: FeedbackSheetAllTypeItem[],
  withoutWrapper: boolean,
  props: {
    myForm: Maybe<FeedbackSheetForm> | undefined | null;
    sheet: FeedbackSheet | null;
    visibleOkrs: Okr[];
    isSelfFeedback: boolean;
  },
): FeedbackSheetItemSaveInput[] => {
  return sortWithoutBreaking<FeedbackSheetAllTypeItem[]>(items, comparatorOrder)
    .filter(item => isItemEnabled(item, withoutWrapper))
    .map(item => changeNullPropsToEmptyString(item) as FeedbackSheetAllTypeItem)
    .map(item => _parseFeedbackSheetItemForSubmissionInitialValues(item, props));
};

export const toFeedbackSheetItemInput = (
  item: FeedbackSheetItemTemplateSaveInput & {
    overallScoreSettings?: string[];
    evaluationScopeSettings?: string[];
    itemTemplate?: FeedbackSheetListItemTemplate | null;
  },
) => {
  const itemValue = { ...omit(item, ['evaluationScopeSettings', 'overallScoreSettings']) };

  (itemValue as any).descriptionLinks = takeAwayEmptyElement<ReferenceLinkInput[]>(
    itemValue.descriptionLinks,
  ).map(descriptionLink => omit(descriptionLink, ['__typename']));

  const resetChildren = () => {
    itemValue.children = [];
  };
  const resetGradeProps = () => {
    itemValue.gradeOptions = [];
  };
  const resetNumberProps = () => {
    itemValue.numberUnit = undefined;
    itemValue.numberMinValue = 0; // default value
    itemValue.numberMaxValue = 100; // default value
  };
  const toGradeProps = (gradeOptions?: any[] | null) =>
    hasElement(gradeOptions)
      ? (gradeOptions || []).map((o: any) => ({
          grade: o?.grade!,
          value: !isNaN(+o?.value) ? +o?.value! : null,
        }))
      : null;

  switch (item?.type) {
    case FeedbackSheetItemTypes.Number:
      itemValue.numberUnit = item.numberUnit;
      itemValue.numberMinValue = +item.numberMinValue! || 0;
      itemValue.numberMaxValue = +item.numberMaxValue!;
      resetGradeProps();
      resetChildren();
      break;

    case FeedbackSheetItemTypes.Grade:
      itemValue.gradeOptions = toGradeProps(item.gradeOptions);
      resetNumberProps();
      resetChildren();
      break;

    case FeedbackSheetItemTypes.Comment:
    case FeedbackSheetItemTypes.Guideline:
      resetGradeProps();
      resetNumberProps();
      resetChildren();
      break;

    case FeedbackSheetItemTypes.Objective:
      itemValue.enableIndividualEvaluation =
        item.evaluationScopeSettings?.includes('enableIndividualEvaluation') ?? false;
      itemValue.enableOverallEvaluation =
        item.evaluationScopeSettings?.includes('enableOverallEvaluation') ?? false;
      itemValue.showTotalScore = item.overallScoreSettings?.includes('showTotalScore') ?? false;
      itemValue.enableTotalWeightValidation =
        item.overallScoreSettings?.includes('enableTotalWeightValidation') ?? false;

      switch (item?.elementType) {
        case FeedbackSheetItemElementTypes.Grade:
          itemValue.gradeOptions = toGradeProps(item.gradeOptions);
          itemValue.allowOtherOptions = item.allowOtherOptions ?? false;
          resetNumberProps();
          resetChildren();
          break;

        case FeedbackSheetItemElementTypes.Number:
          itemValue.numberUnit = item.numberUnit;
          itemValue.numberMinValue = +(item.numberMinValue! || 0);
          itemValue.numberMaxValue = +item.numberMaxValue!;
          resetGradeProps();
          resetChildren();
          break;

        case FeedbackSheetItemElementTypes.Comment:
          resetNumberProps();
          resetGradeProps();
          resetChildren();
          break;

        default:
          break;
      }

      break;
    case FeedbackSheetItemTypes.Property:
      resetGradeProps();
      resetNumberProps();
      resetChildren();
      itemValue.propertyId = item.propertyId ?? null;
      itemValue.periodId = item.periodId ?? null;
      break;

    case FeedbackSheetItemTypes.SingleLine:
      resetGradeProps();
      resetNumberProps();
      resetChildren();
      break;

    case FeedbackSheetItemTypes.Listing:
      itemValue.children =
        (item as any).elementType !== FeedbackSheetItemElementTypes.Section
          ? null
          : takeAwayEmptyElement<FeedbackSheetItemTemplateSaveInput[]>(item.children).map(
              childItem => {
                return {
                  id: childItem?.id,
                  title: childItem.title,
                  type: childItem.type,
                  authorTypes: item.authorTypes,
                  gradeOptions:
                    childItem.type !== FeedbackSheetItemTypes.Grade
                      ? null
                      : !hasElement(childItem.gradeOptions)
                        ? null
                        : takeAwayEmptyElement<any[]>(childItem.gradeOptions).map(gradeOption => {
                            const value = parseFloat(`${gradeOption.value}`);
                            return {
                              grade: gradeOption.grade,
                              value: isNaN(value) ? null : value,
                            };
                          }),
                  allowOtherOptions:
                    childItem.type !== FeedbackSheetItemTypes.Grade
                      ? false
                      : (childItem.allowOtherOptions ?? false),
                };
              },
            );
      break;

    default:
      break;
  }

  return itemValue;
};
export const convertItemsForUpsert = (items: FeedbackSheetItemTemplateSaveInput[]) => {
  return takeAwayEmptyElement<FeedbackSheetItemTemplateSaveInput[]>(items).map(item =>
    toFeedbackSheetItemInput(item),
  );
};

export const isPreValueItem = (i: FeedbackSheetItem | FeedbackSheetItemTemplate) => {
  return (
    i.type === FeedbackSheetItemTypes.Grade ||
    (i.type === FeedbackSheetItemTypes.Listing &&
      (i as FeedbackSheetListItem).elementType === FeedbackSheetItemElementTypes.Section)
  );
};

export const getPreValueItemTemplateIds = (
  items: Maybe<Maybe<FeedbackSheetItemTemplate>[]> | undefined,
) => {
  return getArrayIds(
    takeAwayMaybeElement<FeedbackSheetItem[]>(items).filter(
      item => isPreValueItem(item) && (item as FeedbackSheetListItem).hasPreValue,
    ),
  );
};

export const getPreValueItemTemplateChildrenIdMap = (
  items: Maybe<Maybe<FeedbackSheetItemTemplate>[]> | undefined,
): Record<string, null | string> => {
  return takeAwayMaybeElement<FeedbackSheetItemTemplate[]>(items).reduce(
    (acc, item) => {
      if (
        !isPreValueItem(item) ||
        !(item as FeedbackSheetListItemTemplate).hasPreValue ||
        !hasElement(item.children)
      ) {
        return acc;
      }

      acc[item.id] =
        getArrayIds([
          takeAwayMaybeElement(item.children).find(i => isPreValueItem(i) && i.hasPreValue),
        ])?.[0] || null;

      return acc;
    },
    {} as Record<string, null | string>,
  );
};

export const getPreValueItemTemplate = (
  preValueItems: (FeedbackSheetItemTemplate | FeedbackSheetItem | null)[] | null,
): (FeedbackSheetItemTemplate | FeedbackSheetItem)[] => {
  return takeAwayMaybeElement<(FeedbackSheetItemTemplate | FeedbackSheetItem)[]>(preValueItems)
    .map(item => {
      let children: (FeedbackSheetItemTemplate | FeedbackSheetItem)[] = [];
      if (
        item.children &&
        item.type === FeedbackSheetItemTypes.Listing &&
        (item as any).elementType === FeedbackSheetItemElementTypes.Section
      ) {
        children = getPreValueItemTemplate(item.children);
      }
      return {
        ...item,
        children,
      };
    })
    .filter(item => isPreValueItem(item) && (item as FeedbackSheetListItemTemplate).hasPreValue);
};

export const isFeedbackScheduleArchived = (
  schedule: Maybe<FeedbackSchedule> | undefined,
): boolean => schedule?.isArchived ?? false;

const _feedbackSettingsItemsFormInitialValuesParseItems = (
  items: FeedbackSheetItemTemplate[],
): any => {
  const _parseItem = (
    item: FeedbackSheetItemTemplateSaveInput &
      FeedbackSheetNumberItem & {
        targetCategories?: ObjectiveCategory[];
        targetSheetSchedules?: ObjectiveSheetSchedule[];
        evaluationScopeSettings?: string[];
        overallScoreSettings?: string[];
      },
  ) => {
    return {
      id: item?.id!,
      type: item?.type!,
      elementType: item?.elementType! || FeedbackSheetItemElementTypes.Grade,
      authorTypes: item?.authorTypes!,
      required: item?.required!,
      commentEnabled: item?.commentEnabled ?? true,
      description: item?.description! || '',
      title: item?.title!,
      gradeOptions:
        item?.gradeOptions?.map((o: any) => ({
          grade: o?.grade!,
          value: +o?.value!,
        })) ||
        (item?.allowOtherOptions
          ? []
          : [
              { grade: '', value: null },
              { grade: '', value: null },
            ]),
      allowOtherOptions: item?.allowOtherOptions || false,
      numberUnit: item?.numberUnit! || item?.unit! || '',
      numberMinValue: item?.minValue || item?.numberMinValue || 0,
      numberMaxValue: item?.maxValue || item?.numberMaxValue || 100,
      targetObjectiveType: item?.targetObjectiveType || FeedbackSheetTargetObjectiveTypes.All,
      targetCategoryIds: getArrayIds(item?.targetCategories),
      targetSheetScheduleIds: getArrayIds(item?.targetSheetSchedules),
      includeUncategorized: item?.includeUncategorized ?? false,
      gradeScoreFormula: item?.gradeScoreFormula || null,
      descriptionLinks: takeAwayMaybeElement<ReferenceLink[]>(item.descriptionLinks),
      propertyId: item?.propertyId || null,
      periodId: item?.periodId || null,
      children: takeAwayEmptyElement(item?.children),

      evaluationScopeSettings: (() => {
        if (item?.type !== FeedbackSheetItemTypes.Objective) {
          return null;
        }
        if (item?.evaluationScopeSettings) {
          return item?.evaluationScopeSettings;
        }
        const settings = [];
        if (item.enableIndividualEvaluation) {
          settings.push('enableIndividualEvaluation');
        }
        if (item.enableOverallEvaluation) {
          settings.push('enableOverallEvaluation');
        }
        return settings;
      })(),
      overallScoreSettings: (() => {
        if (item?.type !== FeedbackSheetItemTypes.Objective) {
          return null;
        }
        if (item?.overallScoreSettings) {
          return item?.overallScoreSettings;
        }

        const settings = [];
        if (item.showTotalScore) {
          settings.push('showTotalScore');
        }
        if (item.enableTotalWeightValidation) {
          settings.push('enableTotalWeightValidation');
        }
        return settings;
      })(),
    };
  };

  return sortWithoutBreaking(items, comparatorOrder).map(
    (
      item: FeedbackSheetItemTemplateSaveInput &
        FeedbackSheetNumberItem & {
          targetCategories?: ObjectiveCategory[];
          targetSheetSchedules?: ObjectiveSheetSchedule[];
        },
    ) => _parseItem(item),
  );
};

export const getFeedbackSettingsItemsFormInitialValues = (
  feedbackDataValues:
    | Partial<CompanyFeedbackSheetTemplate | SystemFeedbackSheetTemplate | FeedbackDataValues>
    | undefined
    | null,
  options: {
    defaultItemOnEmpty?: boolean;
  } = {},
): any => {
  const { defaultItemOnEmpty = false } = options || {};

  const items = takeAwayMaybeElement<FeedbackSheetItemTemplate[]>(feedbackDataValues?.items);
  return {
    displayObjectives: feedbackDataValues?.displayObjectives,
    targetObjectiveType:
      feedbackDataValues?.targetObjectiveType || FeedbackSheetTargetObjectiveTypes.All,
    targetCategoryIds: feedbackDataValues?.targetCategoryIds ?? null,
    targetSheetScheduleIds: feedbackDataValues?.targetSheetScheduleIds ?? null,
    includeUncategorized: feedbackDataValues?.includeUncategorized ?? false,
    enableFormMessage: feedbackDataValues?.enableFormMessage ?? true,
    items: hasElement(items)
      ? _feedbackSettingsItemsFormInitialValuesParseItems(items)
      : defaultItemOnEmpty
        ? [defaultFeedbackSheetItemTemplate]
        : [],
  };
};

export const displayTotalWeightForOverall = (
  item:
    | Pick<
        FeedbackSheetObjectiveItemTemplate,
        | 'enableIndividualEvaluation'
        | 'enableOverallEvaluation'
        | 'showTotalScore'
        | 'totalScoreSourceType'
        | 'elementType'
      >
    | undefined,
) => {
  if (!item) return false;

  const { showTotalScore, enableOverallEvaluation, enableIndividualEvaluation } = item;

  if (!enableOverallEvaluation) return false;
  if (!enableIndividualEvaluation) return false;

  if (!showTotalScore) return false;

  return true;
};

export const displayTotalWeightForIndividual = (
  item:
    | Pick<
        FeedbackSheetObjectiveItemTemplate,
        | 'enableOverallEvaluation'
        | 'enableIndividualEvaluation'
        | 'showTotalScore'
        | 'totalScoreSourceType'
        | 'elementType'
      >
    | undefined
    | null,
) => {
  if (!item) return false;

  const { showTotalScore, enableIndividualEvaluation, enableOverallEvaluation } = item;

  if (enableOverallEvaluation) return false;
  if (!enableIndividualEvaluation) return false;

  if (!showTotalScore) return false;

  return true;
};

export const displayTotalScoreForOverall = (
  item:
    | Pick<
        FeedbackSheetObjectiveItemTemplate,
        'enableOverallEvaluation' | 'showTotalScore' | 'totalScoreSourceType' | 'elementType'
      >
    | undefined,
) => {
  if (!item) return false;

  const { showTotalScore, enableOverallEvaluation } = item;

  if (!enableOverallEvaluation) return false;
  if (!showTotalScore) return false;

  return true;
};

export const displayTotalScoreForIndividual = (
  item:
    | Pick<
        FeedbackSheetObjectiveItemTemplate,
        | 'enableOverallEvaluation'
        | 'enableIndividualEvaluation'
        | 'showTotalScore'
        | 'totalScoreSourceType'
        | 'elementType'
      >
    | undefined
    | null,
) => {
  if (!item) return false;

  const { showTotalScore, enableIndividualEvaluation, enableOverallEvaluation } = item;

  if (enableOverallEvaluation) return false;
  if (!enableIndividualEvaluation) return false;

  if (!showTotalScore) return false;

  return true;
};

export const generateFeedbackSheetLayerId = (sheetId: number | string, layerId: number | string) =>
  `${sheetId}:${layerId}`;

export const pickFeedbackSheetLayerAssignees = (
  layer: FeedbackSheetLayer | Maybe<Maybe<FeedbackSheetLayer>> | undefined,
) => {
  return takeAwayMaybeElement<User[]>(
    layer?.members
      ?.filter(
        m =>
          m?.status === FeedbackSheetLayerMemberStatuses.Active ||
          m?.status === FeedbackSheetLayerMemberStatuses.Requested,
      )
      ?.map(m => m?.assignee),
  );
};

export const pickFeedbackSheetLayerMembers = (
  layer: FeedbackSheetLayer | Maybe<Maybe<FeedbackSheetLayer>> | undefined,
) => {
  return takeAwayMaybeElement<FeedbackSheetLayerMember[]>(layer?.members);
};

export const getFeedbackSheetFormRequesterLayer = (
  sheet: FeedbackSheet | undefined,
  form: FeedbackSheetForm | undefined,
): {
  requester: Maybe<User> | undefined | null;
  requesterType: FeedbackLayerTypes | undefined | null;
  layer: FeedbackSheetLayer | undefined | null;
} => {
  if (!form || !sheet) {
    return {
      requester: null,
      requesterType: null,
      layer: null,
    };
  }
  const sheetLayers = takeAwayMaybeElement<FeedbackSheetLayer[]>(sheet?.layers);
  const sheetLayer = sheetLayers.find(sl => sl.layerId === form?.layer?.id);
  const sheetLayerMembers = takeAwayMaybeElement<FeedbackSheetLayerMember[]>(sheetLayer?.members);
  const sheetLayerMember = sheetLayerMembers.find(member => member.formId === form?.id);
  const requester = sheetLayerMember?.requester;
  const layer = sheetLayers.find(sl => sl.type === sheetLayerMember?.requesterType);

  return {
    requester,
    requesterType: sheetLayerMember?.requesterType,
    layer,
  };
};

export const getFeedbackSheetSelectFormOptions = (
  sheet: FeedbackSheet,
  userId: string,
  fbDict?: UseDictionnaryReturn | undefined,
) => {
  const sheetLayers = takeAwayMaybeElement<FeedbackSheetLayer[]>(sheet?.layers);
  const forms = getMyFeedbackSheetForms(sheet, userId);

  return forms.reduce((acc: SelectOption[], f) => {
    const sheetLayer = sheetLayers.find(sl => sl.layerId === f?.layer?.id);
    const sheetLayerMembers = takeAwayMaybeElement<FeedbackSheetLayerMember[]>(sheetLayer?.members);
    const sheetLayerMember = sheetLayerMembers.find(member => member.formId === f.id);
    const requester = sheetLayerMember?.requester;
    const requesterLayer = sheetLayers.find(sl => sl.type === sheetLayerMember?.requesterType);
    const statusLabel =
      f.status === FeedbackSheetFormStatuses.Published
        ? '【公開済】 '
        : f.status === FeedbackSheetFormStatuses.Submitted
          ? '【提出済】 '
          : '';

    if (requester && requesterLayer) {
      acc.push({
        value: f.id,
        label: `${i18n.t('{{statusLabel}}{{requesterName}}({{layerAssignee}})からリクエスト', {
          statusLabel,
          requesterName: requester.displayName,
          layerAssignee: createLabelFromFeedbackSheetLayer(
            requesterLayer,
            {
              isMiddleParallelLayer: isMiddleParallelLayerType(sheet?.schedule?.feedback),
            },
            fbDict,
          ),
        })}`,
      });
    } else {
      acc.push({
        value: f.id,
        label: `${i18n.t('デフォルト')}`,
      });
    }

    return acc;
  }, []);
};

export const defaultFeedbackSheetItemTemplate: FeedbackSheetItemTemplateSaveInput & {
  evaluationScopeSettings?: string[];
  overallScoreSettings?: string[];
} = {
  title: '',
  type: FeedbackSheetItemTypes.Comment,
  description: '',
  descriptionLinks: [],
  numberUnit: '',
  numberMinValue: 0,
  numberMaxValue: 100,
  gradeOptions: [
    {
      grade: '',
      value: null,
    },
    {
      grade: '',
      value: null,
    },
  ],
  allowOtherOptions: false,
  required: true,
  commentEnabled: true,
  authorTypes: [FeedbackSheetItemAuthorTypes.Self, FeedbackSheetItemAuthorTypes.Sender],
  elementType: FeedbackSheetItemElementTypes.Grade,
  targetObjectiveType: FeedbackSheetTargetObjectiveTypes.All,
  targetCategoryIds: [],
  targetSheetScheduleIds: [],
  includeUncategorized: false,
  gradeScoreFormula: null,
  propertyId: null,
  periodId: null,
  children: [],

  enableIndividualEvaluation: true,
  enableOverallEvaluation: false,
  showTotalScore: false,
  totalScoreSourceType: FeedbackSheetTotalScoreSourceTypes.Item,
  enableTotalWeightValidation: false,

  evaluationScopeSettings: ['enableIndividualEvaluation'],
};

export const isUserAllowToPublishTargetForm = (
  userId: string,
  sheet: FeedbackSheet,
  targetForm: FeedbackSheetForm,
) => {
  const myForms = getMyFeedbackSheetForms(sheet, userId);
  const feedback = sheet?.schedule?.feedback;

  const form = myForms?.[0];
  const isFinal = form?.layer?.type === FeedbackLayerTypes.Final;
  const isSupervisor = form?.layer?.type === FeedbackLayerTypes.Supervise;
  const isManual =
    feedback?.enablePublicationBySender &&
    feedback?.publicationType === FeedbackPublicationTypes.Manual;

  const formLayerType = targetForm?.layer?.type;

  return (isFinal || isSupervisor) && isManual && formLayerType !== FeedbackLayerTypes.Self;
};

export const isUserAllowToPublishTargetFormForRequested = (
  userId: string,
  sheet: FeedbackSheet,
  targetForm: FeedbackSheetForm,
) => {
  const myForms = getMyFeedbackSheetForms(sheet, userId);
  const form = myForms?.[0];
  const isFinal = form?.layer?.type === FeedbackLayerTypes.Final;
  const isSupervisor = form?.layer?.type === FeedbackLayerTypes.Supervise;

  const formLayerType = targetForm?.layer?.type;

  return (isFinal || isSupervisor) && formLayerType !== FeedbackLayerTypes.Self;
};

export const _getDummyObjectiveElements = (
  item: FeedbackSheetObjectiveItemTemplate,
  {
    targetObjectiveType,
    targetCategories = [],
    targetCategoryIds = [],

    targetSheetSchedules = [],
    targetSheetScheduleIds = [],
  }: {
    targetObjectiveType?: FeedbackSheetTargetObjectiveTypes;
    targetCategories: ObjectiveCategory[];
    targetCategoryIds: string[];

    targetSheetSchedules: ObjectiveSheetSchedule[];
    targetSheetScheduleIds: string[];
  },
) => {
  const dummyOkrs = _getDummyOkrs();

  switch (targetObjectiveType) {
    case FeedbackSheetTargetObjectiveTypes.SelectedCategory:
      targetCategories = takeAwayEmptyElement<ObjectiveCategory[]>(
        targetCategoryIds?.map(id => targetCategories?.find(oc => oc?.id === id)),
      );
      break;

    case FeedbackSheetTargetObjectiveTypes.SelectedSheetSchedule:
      targetSheetSchedules = takeAwayEmptyElement<ObjectiveSheetSchedule[]>(
        targetSheetScheduleIds?.map(id => targetSheetSchedules?.find(oss => oss?.id === id)),
      );
      break;
  }

  let dummyObjectives = [
    {
      id: '-1',
      formId: '-9999',
      type: item.elementType,
      gradeOptions: item.gradeOptions,
      unit: item.unit,
      minValue: item.minValue,
      maxValue: item.maxValue,
      okrType: OkrTypes.Objective,
      objective: dummyOkrs[0],
      comment: null,

      enabled: true,
      commentEnabled: true,
      valueEnabled: true,
    },
    {
      id: '-2',
      formId: '-9999',
      type: item.elementType,
      gradeOptions: item.gradeOptions,
      unit: item.unit,
      minValue: item.minValue,
      maxValue: item.maxValue,
      okrType: OkrTypes.Objective,
      objective: dummyOkrs[1],
      comment: null,

      enabled: true,
      commentEnabled: true,
      valueEnabled: true,
    },
  ];

  if (hasElement(targetCategories)) {
    dummyObjectives = dummyObjectives.map((dummyObjective, index) => ({
      ...dummyObjective,
      category: targetCategories?.[index] || targetCategories?.[0],
    }));
  }
  return dummyObjectives;
};
const _getDummyOkrs = () => {
  const user = {
    id: '-2',
    displayName: i18next.t('ユーザー'),
  };
  const dummyOkrs: Objective[] = [
    {
      id: '-2',
      title: '目標１',
      achievementRate: 50,
      user,
      okrType: OkrTypes.Objective,
      status: OkrStatuses.Active,
    },
    {
      id: '-1',
      title: '目標２',
      achievementRate: 100,
      user,
      okrType: OkrTypes.Objective,
      status: OkrStatuses.Active,
    },
  ];
  return dummyOkrs;
};

interface GetFeedbackSheetSubmissionFormInitialValuesFromFeedbackSheetTemplateOptions {
  includeDummyOkrs?: boolean;
  preValues?: Maybe<FeedbackRequestPreValue[]> | undefined;
  withoutWrapper?: boolean;
}
export const getFeedbackSubmissionFormSheetItemsFromFeedbackSheetTemplate = (
  template: Maybe<FeedbackSheetTemplate> | undefined,
  options: GetFeedbackSheetSubmissionFormInitialValuesFromFeedbackSheetTemplateOptions = {},
): FeedbackSheetAllTypeItem[] => {
  const { includeDummyOkrs, preValues = [], withoutWrapper = false } = options;

  const itemTemplates = takeAwayMaybeElement<FeedbackSheetItemTemplate[]>(template?.items);

  return itemTemplates.map((itemTemplate, index: number) => {
    /* preValue init*/
    const preValue = preValues?.find(i => i.itemTemplateId === itemTemplate.id);
    const hasPreValue = !!preValue;

    /* common init*/

    const itemWrapper = itemTemplate.itemWrappers?.[0];
    const useDefault = itemWrapper?.useDefault;

    const itemCommons: FeedbackSheetItem = {
      id: '-9999',
      formId: '-9999',
      type: itemTemplate.type,
      required: itemTemplate.required,
      order: itemTemplate.order ?? index,
      title: itemTemplate.title,
      description: itemTemplate.description,
      descriptionLinks: itemTemplate.descriptionLinks,
      itemTemplateId: itemTemplate.id,
      itemTemplate,

      enabled: withoutWrapper || useDefault ? true : itemWrapper?.itemEnabled,
      commentEnabled:
        withoutWrapper || useDefault
          ? (
              itemTemplate as
                | FeedbackSheetGradeItemTemplate
                | FeedbackSheetGradeItemTemplate
                | FeedbackSheetObjectiveItemTemplate
            ).commentEnabled
          : itemWrapper?.commentEnabled,
      valueEnabled: withoutWrapper || useDefault ? true : itemWrapper?.valueEnabled,
      itemRequired:
        withoutWrapper || useDefault
          ? (
              itemTemplate as
                | FeedbackSheetGradeItemTemplate
                | FeedbackSheetGradeItemTemplate
                | FeedbackSheetObjectiveItemTemplate
            ).required
          : itemWrapper?.itemRequired,
    };

    const numberItemTemplate = itemTemplate as FeedbackSheetNumberItemTemplate;
    const objectiveItemTemplate = itemTemplate as FeedbackSheetObjectiveItemTemplate;
    const gradeItemTemplate = itemTemplate as FeedbackSheetGradeItemTemplate;
    const listItemTemplate = itemTemplate as FeedbackSheetListItemTemplate;
    const objectiveItemElementType = objectiveItemTemplate.elementType;

    switch (itemTemplate.type) {
      default:
        return itemCommons;
      case FeedbackSheetItemTypes.Guideline:
        return {
          ...itemCommons,
        } as FeedbackSheetGuidelineItem;

      case FeedbackSheetItemTypes.Comment:
        return {
          ...itemCommons,
        } as FeedbackSheetCommentItem;

      case FeedbackSheetItemTypes.SingleLine:
        return {
          ...itemCommons,
        } as FeedbackSheetSingleLineItem;

      case FeedbackSheetItemTypes.Number:
        return {
          ...itemCommons,
          maxValue: numberItemTemplate.maxValue,
          minValue: numberItemTemplate.minValue,
          unit: numberItemTemplate.unit,
        } as FeedbackSheetNumberItem;

      case FeedbackSheetItemTypes.Property:
        return {
          ...itemCommons,
          userProperty: null,
        } as FeedbackSheetPropertyItem;

      case FeedbackSheetItemTypes.Calculate:
        return {
          ...itemCommons,
        } as FeedbackSheetCalculateItem;

      case FeedbackSheetItemTypes.Grade:
        const gradeItem: FeedbackSheetGradeItem = {
          ...itemCommons,
          allowOtherOptions: gradeItemTemplate.allowOtherOptions,
          gradeOptions: gradeItemTemplate.gradeOptions,
          itemTemplate: {
            ...itemTemplate,
            hasPreValue,
          },
          hasPreValue,
          otherOption: preValue?.otherOption,
          grade: preValue?.gradeValue,
        };

        return {
          ...gradeItem,
          ..._parseGradeValuesForSubmission(gradeItem),
        } as FeedbackSheetGradeItem;

      case FeedbackSheetItemTypes.Listing:
        const listingItemElementType = listItemTemplate.elementType;
        const listingItemGradeOptions = listItemTemplate.gradeOptions;

        const _listingItem: FeedbackSheetListItem = {
          ...itemCommons,
          itemTemplate: {
            ...itemTemplate,
            elementType: listingItemElementType,
            hasPreValue,
          },
          elementType: listingItemElementType,
          allowOtherOptions: listItemTemplate.allowOtherOptions,
          gradeOptions: listingItemGradeOptions,

          maxValue: listItemTemplate.maxValue,
          minValue: listItemTemplate.minValue,
          unit: listItemTemplate.unit,

          hasPreValue,
        };

        const listingItem = {
          ..._listingItem,
          elements: preValue?.elements?.map(preValueElement => {
            const element = {
              id: '9999',
              type: FeedbackSheetItemElementTypes.Section,
              ..._parseGradeValuesForSubmission({
                otherOption: preValueElement.otherOption,
                grade: preValueElement.gradeValue,
                allowOtherOptions: !!preValueElement.otherOption,
                gradeOptions: listingItemGradeOptions,
                hasPreValue: hasPreValue,
              }),
            };

            const elementChildren = toFeedbackSheetItemElementChildren(element, _listingItem);
            return {
              ...element,
              children: elementChildren,
            };
          }),
        };
        return listingItem;

      case FeedbackSheetItemTypes.Section:
        return {
          ...itemCommons,
        } as FeedbackSheetSectionItem;

      case FeedbackSheetItemTypes.Objective:
        return {
          ...itemCommons,
          itemTemplate: {
            ...objectiveItemTemplate,
            elementType: objectiveItemElementType,
            hasPreValue,
            targetCategories: objectiveItemTemplate?.targetCategories,
            targetSheetSchedules: objectiveItemTemplate?.targetSheetSchedules,
            targetObjectiveType: objectiveItemTemplate?.targetObjectiveType,
          },
          elementType: objectiveItemElementType,

          allowOtherOptions: objectiveItemTemplate.allowOtherOptions,
          gradeOptions: objectiveItemTemplate.gradeOptions,

          maxValue: objectiveItemTemplate.maxValue,
          minValue: objectiveItemTemplate.minValue,
          unit: objectiveItemTemplate.unit,

          elements: [],
          okrs: includeDummyOkrs ? _getDummyOkrs() : [],

          hasPreValue,
        } as FeedbackSheetObjectiveItem;
    }
  });
};
