import i18next from 'i18next';
import { icons } from 're-design';

import { toast } from '../../components/atoms/Toast';

const RefreshIcon = <icons.RefreshIcon width="18px" height="18px" fill="white" />;

const showVersionUpToast = () => {
  if (process.env.NODE_ENV === 'production' && !toast.isActive('versionUpLink')) {
    toast.info(
      i18next.t(
        'Wistantの新しいバージョンがリリースされました。ここをクリックして画面を更新してください',
      ),
      {
        toastId: 'versionUpLink',
        autoClose: false,
        closeButton: RefreshIcon,
        onClick: () => {
          if (caches) {
            caches.keys().then(function (names) {
              for (const name of names) caches.delete(name);
            });
          }
          window.location.reload();
        },
      },
    );
  }
};

export default showVersionUpToast;
