const url = {
  root: '/',
  index: '/',
  home: '/home',
  login: {
    index: '/login',
  },
  passwordReset: {
    index: '/password-reset',
    requestForm: '/password-reset/request-form',
    registerForm: '/password-reset/register-form',
  },
  signup: {
    index: '/signup',
  },
  register: {
    index: '/register',
  },

  extO3s: {
    index: '/ext-one-on-ones',
    meetings: {
      index: '/ext-one-on-ones/meetings',
      detail: {
        index: '/ext-one-on-ones/meetings/:meetingId',
      },
    },
  },

  o3s: {
    index: '/one-on-ones',
    meetings: {
      index: '/one-on-ones/meetings',
      detail: {
        index: '/one-on-ones/meetings/:meetingId',
        preSurvey: {
          index: '/one-on-ones/meetings/:meetingId/pre-survey',
          begin: '/one-on-ones/meetings/:meetingId/pre-survey/begin',
          end: '/one-on-ones/meetings/:meetingId/pre-survey/end',
          keep: '/one-on-ones/meetings/:meetingId/pre-survey/keep',
          problem: '/one-on-ones/meetings/:meetingId/pre-survey/problem',
          action: '/one-on-ones/meetings/:meetingId/pre-survey/action',
          topic: '/one-on-ones/meetings/:meetingId/pre-survey/topic',
          demand: '/one-on-ones/meetings/:meetingId/pre-survey/demand',
        },
      },
    },
  },

  feedbacks: {
    index: '/feedbacks',
    detail: {
      index: '/feedbacks/:feedbackId',
      sheets: {
        index: '/feedbacks/:feedbackId/sheets',
        detail: {
          index: '/feedbacks/:feedbackId/sheets/:sheetId',
        },
      },
    },
  },

  objectiveSheets: {
    index: '/objective-sheets',
    detail: {
      index: '/objective-sheets/:sheetId',
    },
  },

  onboarding: {
    index: '/onboarding',
    avatar: '/onboarding?page=avatar',
    summary: '/onboarding?page=summary',
    checkin: '/onboarding?page=checkin',
  },

  // 1on1プラン (payment plan o3) ------ START
  ppo3: {
    index: '/ppo3',

    // 1on1シート（あなたの1on1）
    o3s: {
      index: '/ppo3/one-on-ones',
      // メンターとの1on1
      extMentors: {
        index: '/ppo3/one-on-ones/ext-mentors',
        detail: {
          index: '/ppo3/one-on-ones/ext-mentors/:mentorId',
          sheets: '/ppo3/one-on-ones/ext-mentors/:mentorId/sheets',
          profile: '/ppo3/one-on-ones/ext-mentors/:mentorId/profile',
        },
      },
      mentors: {
        current: '/ppo3/one-on-ones/mentors?current',
        past: '/ppo3/one-on-ones/mentors?past',
        index: '/ppo3/one-on-ones/mentors',
      },
      // メンティとの1on1
      mentees: {
        current: '/ppo3/one-on-ones/mentees?current',
        past: '/ppo3/one-on-ones/mentees?past',
        index: '/ppo3/one-on-ones/mentees',
      },
    },

    // 1on1分析（あなたのチーム）
    organization: {
      index: '/ppo3/organization',
      // 会社
      company: {
        index: '/ppo3/organization/company',
        pairs: {
          index: '/ppo3/organization/company/pairs',
          detail: {
            index: '/ppo3/organization/company/pairs/:pairId',
          },
        },
      },
      // チーム
      teams: {
        index: '/ppo3/organization/teams',
        detail: {
          index: '/ppo3/organization/teams/:teamId',
          noteam: '/ppo3/organization/teams/noteam',
        },
        pairs: {
          index: '/ppo3/organization/teams/:teamId/pairs',
          detail: {
            index: '/ppo3/organization/teams/:teamId/pairs/:pairId',
          },
        },
      },
    },

    // 1on1設定
    settings: {
      index: '/ppo3/settings',
      // ペア設定
      pair: {
        index: '/ppo3/settings/pair',
        pair: '/ppo3/settings/pair?pair',
        member: '/ppo3/settings/pair?member',
        team: {
          index: '/ppo3/settings/pair-by-team',
          pair: '/ppo3/settings/pair-by-team?pair',
          member: '/ppo3/settings/pair-by-team?member',
        },
      },
      // 事前アンケート設定
      presurvey: {
        index: '/ppo3/settings/presurvey',
        detail: {
          index: '/ppo3/settings/presurvey/:templateId',
          preview: '/ppo3/settings/presurvey/:templateId/preview',
        },
      },
      // ガイドライン設定
      guideline: {
        index: '/ppo3/settings/guideline',
        detail: {
          edit: '/ppo3/settings/guideline/edit',
        },
      },
      // 社外メンター案件設定
      extMentors: {
        index: '/ppo3/settings/ext-mentors',
        project: {
          index: '/ppo3/settings/ext-mentors/project/:projectId',
          tickets: '/ppo3/settings/ext-mentors/project/:projectId/tickets',
          mentors: '/ppo3/settings/ext-mentors/project/:projectId/mentors',
          mentees: '/ppo3/settings/ext-mentors/project/:projectId/mentees',
          session: '/ppo3/settings/ext-mentors/project/:projectId/session',
          reports: {
            index: '/ppo3/settings/ext-mentors/project/:projectId/reports',
            detail: {
              index: '/ppo3/settings/ext-mentors/project/:projectId/reports/:pairId',
            },
          },
        },
      },
    },

    // 組織
    platform: {
      index: '/ppo3/platform',
      members: {
        index: '/ppo3/platform/members',
        list: {
          index: '/ppo3/platform/members/list/all',
          hrp: '/ppo3/platform/members/list/hrp',
          owner: '/ppo3/platform/members/list/owner',
          hrbp: '/ppo3/platform/members/list/hrbp',
        },
      },
      teams: {
        index: '/ppo3/platform/teams',
      },
      reportLine: {
        index: '/ppo3/platform/report-lines',
      },
    },
  },

  // 1on1プラン (payment plan o3) ------ END

  selfManagement: {
    index: '/self-management',
    activity: {
      index: '/self-management/activity',
    },
    myPage: {
      index: '/self-management/my-page',
    },
    objectives: {
      index: '/self-management/objectives',
      list: {
        index: '/self-management/objectives/list',
      },
      sheet: {
        index: '/self-management/objectives/sheets',
        detail: {
          index: '/self-management/objectives/sheets/:sheetId',
        },
      },
    },
    document: {
      index: '/self-management/collab',
      detail: '/self-management/collab/:documentId',
    },
    o3s: {
      index: '/self-management/one-on-ones',
      current: '/self-management/one-on-ones?current',
      past: '/self-management/one-on-ones?past',
    },
    extMentors: {
      index: '/self-management/ext-one-on-ones',
      detail: {
        index: '/self-management/ext-one-on-ones/:mentorId',
        meetings: '/self-management/ext-one-on-ones/:mentorId/meetings',
        profile: '/self-management/ext-one-on-ones/:mentorId/profile',
      },
    },
    feedbacks: {
      index: '/self-management/feedbacks',
      realtime: {
        index: '/self-management/feedbacks/realtime',
        detail: '/self-management/feedbacks/realtime/:realTimeFeedbackId',
      },
      detail: {
        index: '/self-management/feedbacks/:feedbackId',
      },
    },
    checkIn: {
      index: '/self-management/check-in',
      onboarding: '/self-management/check-in?onboarding=true',
    },
    objectiveSheets: {
      index: '/self-management/objective-sheets',
    },
  },
  peopleManagement: {
    index: '/people-management',
    memberBoard: {
      index: '/people-management/member-board',
      detail: {
        index: '/people-management/member-board/:memberId/',
        support: '/people-management/member-board/:memberId/support',
        objectives: '/people-management/member-board/:memberId/objectives',
        objectiveSheets: '/people-management/member-board/:memberId/objective-sheets',
        actions: '/people-management/member-board/:memberId/actions',
        o3s: '/people-management/member-board/:memberId/one-on-ones',
        feedbacks: {
          index: '/people-management/member-board/:memberId/feedbacks',
          detail: {
            index: '/people-management/member-board/:memberId/feedbacks/:feedbackId',
          },
        },
        realTimeFeedbacks: {
          index: '/people-management/member-board/:memberId/rtfbs',
          detail: {
            index: '/people-management/member-board/:memberId/rtfbs/:realTimeFeedbackId',
          },
        },
        activity: '/people-management/member-board/:memberId/activity',
        profile: '/people-management/member-board/:memberId/profile',
      },
    },
    objectives: {
      index: '/people-management/objectives',
      query: {
        member: '/people-management/objectives?member',
        objective: '/people-management/objectives?objective',
      },
    },
    o3s: {
      index: '/people-management/one-on-ones',
      current: '/people-management/one-on-ones?current',
      past: '/people-management/one-on-ones?past',
    },
    feedbacks: {
      index: '/people-management/feedbacks',
      detail: {
        index: '/people-management/feedbacks/:feedbackId',
        notSubmitted: '/people-management/feedbacks/:feedbackId/not-submitted',
        viewable: '/people-management/feedbacks/:feedbackId/viewable',
        forms: '/people-management/feedbacks/:feedbackId/forms',
      },
      realtime: {
        index: '/people-management/feedbacks/realtime',
        detail: '/people-management/feedbacks/realtime/:realTimeFeedbackId',
      },
    },
    objectiveSheets: {
      index: '/people-management/objective-sheets',
      list: {
        index: '/people-management/objective-sheets/list',
      },
      request: {
        index: '/people-management/objective-sheets/request',
      },
    },
  },
  organization: {
    index: '/organization',
    company: {
      index: '/organization/company',
      detail: {
        keyMetricTags: {
          index: '/organization/company/metrics',
        },
        objectives: {
          index: '/organization/company/objectives',
        },
        teams: '/organization/company/teams',
      },
    },
    teams: {
      index: '/organization/teams',
      detail: {
        index: '/organization/teams/:teamId',
        objectives: {
          index: '/organization/teams/:teamId/objectives',
        },
        members: '/organization/teams/:teamId/members',
        memberObjectives: '/organization/teams/:teamId/member-objectives',
      },
    },
    members: {
      index: '/organization/members',
    },
  },
  hr: {
    index: '/hr',
    dashboard: {
      index: '/hr/dashboard',
      menu: '/hr/dashboard/menu',
      overview: '/hr/dashboard/overview',
      transition: '/hr/dashboard/transition',
      compare: '/hr/dashboard/compare',
    },
    peopleManagement: {
      index: '/hr/people-management',
      objectiveSheet: {
        index: '/hr/people-management/objective-sheet',
        create: {
          index: '/hr/people-management/objective-sheet/create',
        },
        detail: {
          index: '/hr/people-management/objective-sheet/:templateId',
          status: '/hr/people-management/objective-sheet/:templateId/status',
          forms: '/hr/people-management/objective-sheet/:templateId/forms',
          settings: '/hr/people-management/objective-sheet/:templateId/settings',
          form: '/hr/people-management/objective-sheet/:templateId/form',
          schedules: {
            index: '/hr/people-management/objective-sheet/:templateId/schedules',
          },
        },
      },
      objectives: {
        index: '/hr/people-management/objectives',
        status: '/hr/people-management/objectives/status',
        achievement: '/hr/people-management/objectives/status',
        company: '/hr/people-management/objectives/company',
        team: '/hr/people-management/objectives/teams',
        updateRate: '/hr/people-management/objectives/update-rate',
        checkin: {
          index: '/hr/people-management/objectives/check-in',
          user: '/hr/people-management/objectives/check-in/user/:userId',
        },
        settings: '/hr/people-management/objectives/settings',
        guideline: {
          index: '/hr/people-management/objectives/guideline',
          detail: {
            edit: '/hr/people-management/objectives/guideline/edit',
          },
        },
        category: {
          index: '/hr/people-management/objectives/category',
        },
        export: '/hr/people-management/objectives/export',
        list: '/hr/people-management/objectives/list',
      },
      extMentors: {
        index: '/hr/people-management/ext-mentors',
        project: {
          index: '/hr/people-management/ext-mentors/project/:projectId',
          tickets: '/hr/people-management/ext-mentors/project/:projectId/tickets',
          mentors: '/hr/people-management/ext-mentors/project/:projectId/mentors',
          mentees: '/hr/people-management/ext-mentors/project/:projectId/mentees',
          session: '/hr/people-management/ext-mentors/project/:projectId/session',
          reports: {
            index: '/hr/people-management/ext-mentors/project/:projectId/reports',
            detail: {
              index: '/hr/people-management/ext-mentors/project/:projectId/reports/:pairId',
            },
          },
        },
      },
      o3s: {
        index: '/hr/people-management/one-on-ones',
        status: '/hr/people-management/one-on-ones/status',
        satisfaction: '/hr/people-management/one-on-ones/satisfaction',
        topics: '/hr/people-management/one-on-ones/topics',
        comment: '/hr/people-management/one-on-ones/comment',
        team: '/hr/people-management/one-on-ones/team/:teamId',
        mentee: '/hr/people-management/one-on-ones/mentee/:menteeId', // TODO: 未実装
        mentor: '/hr/people-management/one-on-ones/mentor/:mentorId',
        pairSettings: {
          index: '/hr/people-management/one-on-ones/pair-settings',
          pair: '/hr/people-management/one-on-ones/pair-settings?pair',
          member: '/hr/people-management/one-on-ones/pair-settings?member',
        },
        surveySettings: {
          index: '/hr/people-management/one-on-ones/survey-settings',
          detail: {
            index: '/hr/people-management/one-on-ones/survey-settings/:templateId',
            preview: '/hr/people-management/one-on-ones/survey-settings/:templateId/preview',
          },
        },
        guideline: {
          index: '/hr/people-management/one-on-ones/guideline',
          detail: {
            edit: '/hr/people-management/one-on-ones/guideline/edit',
          },
        },
      },
      feedback: {
        index: '/hr/people-management/feedback',
        draft: '/hr/people-management/feedback/draft',
        realTimeFeedbacks: {
          index: '/hr/people-management/feedback/rtfbs',
          detail: {
            index: '/hr/people-management/feedback/rtfbs/:realTimeFeedbackId',
          },
        },
        feedbacks: {
          index: '/hr/people-management/feedback/feedbacks',
          create: {
            index: '/hr/people-management/feedback/feedbacks/create',
            step: {
              overview: '/hr/people-management/feedback/feedbacks/create/step/overview',
              confirm: '/hr/people-management/feedback/feedbacks/create/step/confirm',
            },
          },
          detail: {
            index: '/hr/people-management/feedback/feedbacks/:feedbackId',
            status: '/hr/people-management/feedback/feedbacks/:feedbackId/status',
            result: '/hr/people-management/feedback/feedbacks/:feedbackId/result',
            forms: '/hr/people-management/feedback/feedbacks/:feedbackId/forms',
            pairs: '/hr/people-management/feedback/feedbacks/:feedbackId/pairs',
            templates: {
              index: '/hr/people-management/feedback/feedbacks/:feedbackId/templates',
              list: {
                index: '/hr/people-management/feedback/feedbacks/:feedbackId/templates/list',
                detail:
                  '/hr/people-management/feedback/feedbacks/:feedbackId/templates/detail/:templateId',
              },
              customize: {
                index: '/hr/people-management/feedback/feedbacks/:feedbackId/templates/customize',
                create:
                  '/hr/people-management/feedback/feedbacks/:feedbackId/templates/customize/create',
                edit: '/hr/people-management/feedback/feedbacks/:feedbackId/templates/customize/edit/:customizeId',
              },
              requestSettings: {
                index:
                  '/hr/people-management/feedback/feedbacks/:feedbackId/templates/request-settings',
              },
            },
            settings: {
              index: '/hr/people-management/feedback/feedbacks/:feedbackId/settings',
            },
            schedules: {
              index: '/hr/people-management/feedback/feedbacks/:feedbackId/schedules',
              create: {
                index: '/hr/people-management/feedback/feedbacks/:feedbackId/schedules/create',
                step: {
                  overview:
                    '/hr/people-management/feedback/feedbacks/:feedbackId/schedules/create/step/overview',
                  date: '/hr/people-management/feedback/feedbacks/:feedbackId/schedules/create/step/date',
                  form: '/hr/people-management/feedback/feedbacks/:feedbackId/schedules/create/step/form',
                  confirm:
                    '/hr/people-management/feedback/feedbacks/:feedbackId/schedules/create/step/confirm',
                },
              },
            },
          },
        },
      },
    },
    platform: {
      members: {
        index: '/hr/platform/members',
        list: {
          index: '/hr/platform/members/list/all',
          hrp: '/hr/platform/members/list/hrp',
          owner: '/hr/platform/members/list/owner',
          hrbp: '/hr/platform/members/list/hrbp',
        },
      },
      teams: {
        index: '/hr/platform/teams',
      },
      reportLine: {
        index: '/hr/platform/report-line',
      },
      groupTags: {
        index: '/hr/platform/groups',
        members: '/hr/platform/groups/:groupId/tags/:tagId',
      },
      customProperties: {
        index: '/hr/platform/custom-properties',
        detail: '/hr/platform/custom-properties/detail/:propertyId',
      },
    },
  },
  import: {
    members: '/import/members/:sessionKey',
    teams: '/import/teams/:sessionKey',
    pairs: '/import/pairs/:sessionKey',
    reportLine: '/import/report-line/:sessionKey',
  },
  settings: {
    index: '/settings',
    platform: {
      members: {
        index: '/settings/members',
      },
      teams: {
        index: '/settings/teams',
      },
    },
    company: {
      index: '/settings/company',
      dataExport: {
        index: '/settings/company/data-export',
      },
      profile: {
        index: '/settings/company/profile',
      },
      notification: {
        index: '/settings/notification',
      },
      period: {
        index: '/settings/period',
      },
      planType: {
        index: '/settings/plan-type',
      },
      security: {
        index: '/settings/security',
        passwordExpiration: '/settings/security/password-expiration',
        saml: '/settings/security/saml',
        ipRestriction: '/settings/security/ip-restriction',
      },
      billing: {
        index: '/settings/billing',
        subscribe: {
          index: '/settings/billing/subscribe',
        },
        invoice: {
          detail: '/settings/billing/invoice/:invoiceId',
        },
      },
      dictionary: {
        index: '/settings/dictionary',
      },
    },
    integrations: {
      slack: '/settings/integrations/slack',
      chatwork: '/settings/integrations/chatwork',
      talknote: '/settings/integrations/talknote',
      msteams: '/settings/integrations/msteams',
      google: {
        index: '/settings/integrations/google',
        login: '/settings/integrations/google/login',
        calendar: '/settings/integrations/google/calendar',
      },
      hrmos: '/settings/integrations/hrmos',
    },
  },
  account: {
    index: '/account',
    profile: '/account/profile',
    security: '/account/security',
    language: '/account/language',
    integrations: {
      index: '/account/integrations',
      googleCalendar: '/account/integrations/googleCalendar',
    },
  },
  test: {
    index: '/test',
  },
};

export default url;
